import moment from 'moment';
import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class LoadSchedule {
  address1: string;
  address3: string;
  batchTimeMinutes: number;
  customerName: string;
  dispatchNotes: string;
  imperial: true;
  addPlusLoad: boolean;
  loadsTicketed: number;
  object: string;
  minutes: number; // not used
  orderFlagId: number;
  orderId: number;
  orderNumber: number;
  productDescription: string;
  productNumber: string;
  plantColorId: number;
  quantity: number;
  scheduleId: number;
  scheduleIntervalMinutes: number;
  scheduleLoadId: number;
  scheduleStatusSymbol: string;
  statusId: string;
  ticketedPlantId: number;
  ticketedPlantDescription: string;
  travelToJobMinutes: number;
  unitOfMeasureId: number;
  yardWashTimeMinutes: number;

  private _firstLoadOnJob: Moment;
  get firstLoadOnJob(): Moment {
    return this._firstLoadOnJob;
  }
  set firstLoadOnJob(val) {
    this._firstLoadOnJob = convertIso8601ToDate(val);
  }

  private _onJob: Moment;
  get onJob(): Moment {
    return this._onJob;
  }
  set onJob(val) {
    this._onJob = convertIso8601ToDate(val);
  }

  public calculateMinutes_OLD(): void {
    this.minutes =
      this.onJob.diff(moment(), 'minutes') -
      this.batchTimeMinutes -
      this.yardWashTimeMinutes -
      this.travelToJobMinutes;
  }

  public calculateMinutes(): number {
    return (
      this.onJob.diff(moment(), 'minutes') -
      this.batchTimeMinutes -
      this.yardWashTimeMinutes -
      this.travelToJobMinutes
    );
  }

  public setShowPlus(variable: boolean): void {
    this.addPlusLoad = variable;
  }
}

export class LoadScheduleResponse {
  lsData: LoadSchedule[];
  plants: number[];

  constructor(lsData: LoadSchedule[], plantIds: number[]) {
    this.lsData = lsData;
    this.plants = plantIds;
  }
}
