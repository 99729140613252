import { AgRendererComponent } from 'ag-grid-angular';
import { BatchLoad } from '@app/batch-load-screen/batch-load.model';
import { BlsTableComponent } from '@app/batch-load-screen/bls-table/bls-table.component';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { ICellRendererParams } from 'ag-grid-community';
import { LoadSchedule } from '@app/truck-tracking/load-scheduler/load-schedule.model';
import { Logger } from '@app/logger/logger';
import { OipLoad } from '@app/truck-tracking/orders-in-progress/oip-load.model';
import { PlantService } from '@app/plants/shared/plant.service';
import { PartialPlant } from '@app/plants/shared/partial-plant.model';
import { Subscription } from 'rxjs';
import { SystemType } from '@app/shared/services/system-type/system-type.service';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';

@Component({
  selector: 'acs-ag-grid-cell-plant',
  templateUrl: './ag-grid-cell-plant.component.html'
})
export class AgGridCellPlantComponent implements AgRendererComponent, OnDestroy {
  public params: ICellRendererParams;
  public getIdentifier = getIdentifierForIterableItem;
  public iconColor: string;
  public plants: Array<PartialPlant> = [];
  public selectedPlant: number;

  private plantSubscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private colorService: ColorService,
    private logger: Logger,
    private plantService: PlantService
  ) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    if (this.params.node.data.plantColorId !== undefined) {
      const color = this.colorService.getColor(this.params.node.data.plantColorId);
      color ? (this.iconColor = color.hex) : (this.iconColor = 'transparent');
    } else {
      const plantColorId = this.plantService.getPlantColorId(this.params.value);

      if (plantColorId) {
        const color = this.colorService.getColor(plantColorId);
        color ? (this.iconColor = color.hex) : (this.iconColor = 'transparent');
      }
    }
    this.getPlants();
    this.setLocalModel();
  }

  ngOnDestroy(): void {
    this.plantSubscription.unsubscribe();
  }

  public setLocalModel(): void {
    if (this.params.node.data instanceof BatchLoad) {
      this.selectedPlant = this.params.node.data.plantId;
    } else if (this.params.node.data instanceof LoadSchedule) {
      this.selectedPlant = this.params.node.data.ticketedPlantId;
    } else if (this.params.node.data instanceof OipLoad) {
      this.selectedPlant = this.params.value;
    }
  }

  public setParamModel(): void {
    if (this.params.node.data instanceof BatchLoad) {
      this.params.node.data.plantId = this.selectedPlant;
    } else if (this.params.node.data instanceof LoadSchedule) {
      this.params.node.data.ticketedPlantId = this.selectedPlant;
    }
  }

  public refresh(params: ICellRendererParams): boolean {
    this.params = params;
    if (this.params.node.data.plantColorId) {
      const color = this.colorService.getColor(this.params.node.data.plantColorId);
      color ? (this.iconColor = color.hex) : (this.iconColor = 'transparent');
    } else {
      const plantColorId = this.plantService.getPlantColorId(this.params.value);

      if (plantColorId) {
        const color = this.colorService.getColor(plantColorId);
        color ? (this.iconColor = color.hex) : (this.iconColor = 'transparent');
      }
    }
    this.getPlants();
    this.setLocalModel();
    return true;
  }

  public setPlantColorId(): void {
    this.params.value = this.plants.find((x) => x.id === this.selectedPlant).name;
    this.setParamModel();
  }

  public getPlants(): void {
    if (this.params.context.componentParent.showRowInEditMode(this.params.node)) {
      const systemTypeId = this.params.context.componentParent.systemTypeId;
      if (systemTypeId === SystemType.Block.id && this.params.node.data instanceof BatchLoad) {
        this.getPlantsBlockBatchLoad();
      } else {
        this.getPlantsBasic(systemTypeId);
      }
    }
  }

  private getPlantsBlockBatchLoad(): void {
    const parent = this.params.context.componentParent as BlsTableComponent;
    const productIds = parent.selectedNodeProducts.map((x) => x.productId);
    this.plantService
      .getPlantsForProduct(undefined, SystemType.Block.id, productIds)
      .subscribe((plants: Array<PartialPlant>) => {
        this.plants = plants.filter((x) => !x.inactiveFlag);
      });
  }

  // Get plants for systemTypeId and single product. This is
  // the default case
  private getPlantsBasic(systemTypeId: number): void {
    let productId = 0;

    if (this.params.context.componentParent.rowSelectedOrderForm) {
      this.params.context.componentParent.rowSelectedOrderForm.schedules.forEach((s) => {
        if (s.schedule.id === this.params.node.data.scheduleId) {
          productId = s.primaryProduct.productId;
        }
      });
    } else if (this.params.node.data.productId) {
      productId = this.params.node.data.productId;
    }

    if (!productId) {
      this.logger.error(
        'Could not find product id',
        this.params.node.data.productId,
        this.params.context.componentParent.rowSelectedOrderForm
      );
      return;
    }

    this.plantSubscription = this.plantService
      .getPlantsForProduct(productId, systemTypeId)
      .subscribe((plants: Array<PartialPlant>) => {
        this.plants = [...plants.filter((x) => !x.inactiveFlag)];
        // without this the user has to click the empty select to show the options
        this.cdr.detectChanges();
      });
  }
}
