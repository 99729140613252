import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ORDER_LOOKUP_API_FIELDS } from '@app/orders/models/order-lookup.model';
import {
  LookupConfigDateType,
  getDateValueForConfigEnum
} from '@app/ui/autocomplete-chiplist/lookup-config-date-type.enum';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Observable, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { areFalseyEqual } from '@app/shared/utilities/comparison-helpers.utility';
import { map, startWith, takeUntil } from 'rxjs/operators';

export interface IChiplistObject {
  readonly id: any;
}

export class SearchableFiltersGeneric {
  id: number;
  name: string;
  objectProperty: string;
  isActive: boolean;
  dateType?: LookupConfigDateType;

  constructor(id: number, name: string, objectProperty: string, dateType: LookupConfigDateType) {
    this.id = id;
    this.name = name;
    this.objectProperty = objectProperty;
    this.dateType = dateType;
    this.isActive = false;
  }
}

class SelectedFilter {
  name: string;
  viewValue: any;
  value: any;

  constructor(name: string, viewValue: any, value: any) {
    this.name = name;
    this.viewValue = viewValue;
    this.value = value;
  }
}

const filtersMap = new Map<number, typeof ORDER_LOOKUP_API_FIELDS>([[1, ORDER_LOOKUP_API_FIELDS]]);

type ChipListFilter = Array<IChiplistObject> | Array<SearchableFiltersGeneric> | Array<string>;

@Component({
  selector: 'acs-autocomplete-chiplist',
  styles: [
    '.mat-mdc-form-field {margin: 8px 8px 0 8px ; font-size: 14px;}',
    '.mat-mdc-chip {background-color:#cceef9 }',
    '.mat-mdc-chip:hover {background-color:#9adef3 }'
  ],
  templateUrl: './generic-autocomplete-chiplist.component.html'
})
export class GenericAutocompleteChiplistComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChild('filterInput', { static: false })
  filterInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autoComplete: MatAutocompleteTrigger;
  @Input() filterPlaceHolder: string;
  @Input() masterDataSet: Array<IChiplistObject>;
  @Input() searchableFilterKey: number;
  @Output() dataSourceUpdated = new EventEmitter<Array<IChiplistObject>>();

  public autocompleteFilterForm = new UntypedFormGroup({
    filter: new UntypedFormControl()
  });

  // items used to build the mat-autocomplete options
  public activeFilter: SearchableFiltersGeneric;
  public filteredCategories: Observable<ChipListFilter>;
  public iterableDiffer: IterableDiffer<IChiplistObject>;
  public searchableFilters: Array<SearchableFiltersGeneric>;
  public selectedFilters: Array<SelectedFilter> = [];

  private destroyed$ = new Subject<void>();

  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    // todo fix bad typing rules here
    const type = filtersMap.get(this.searchableFilterKey);
    this.searchableFilters = Object.keys(type).map(
      (key, i) =>
        new SearchableFiltersGeneric(
          i,
          type[key].displayValue ?? type[key].apiField.replace(/_/g, ' '),
          type[key].clientField,
          type[key].dateType
        )
    );

    this.filteredCategories = this.autocompleteFilterForm.get('filter').valueChanges.pipe(
      takeUntil(this.destroyed$),
      startWith(null as string),
      map((category: SearchableFiltersGeneric | string | null) => {
        if (typeof category === 'string') {
          if (category.endsWith(':') && !category.endsWith(': ')) {
            this.filterInput.nativeElement.value = '';
            this.autocompleteFilterForm.get('filter').setValue('');
            category = null;
            this.activeFilter = undefined;
          }
        }

        return category ? this.filter(category) : this.searchableFilters?.slice();
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.autoComplete.closePanel();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngDoCheck(): void {
    // not necessary for current usage which is only in OE
    // if (this.iterableDiffer.diff(this.masterDataSet)) {
    //   if (this.masterDataSet.length) {
    //     this.autocompleteFilterForm.get('filter').reset('', { emitEvent: false });
    //     this.selectedFilters = [];
    //     this.setDataSource();
    //   }
    // }
  }

  public remove(filter: SelectedFilter): void {
    const index = this.selectedFilters.findIndex(
      (x) => x.name === filter.name && x.value === filter.value
    );
    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
    }
    this.setDataSource();
  }

  public setDataSource(): void {
    if (!this.selectedFilters.length) {
      this.dataSourceUpdated.emit(this.masterDataSet);
      return;
    }

    let filteredItems = this.masterDataSet;

    this.selectedFilters.forEach((filter) => {
      const category = filter.name.toLowerCase().trim();
      const viewValue = filter.viewValue;
      const filterItem = this.searchableFilters.find((o) => o.name.toLowerCase() === category);
      filteredItems = filteredItems.filter((p) => {
        if (p[filterItem.objectProperty]) {
          if (filterItem.dateType !== LookupConfigDateType.None) {
            const dateFromObject = getDateValueForConfigEnum(
              filterItem.dateType,
              p[filterItem.objectProperty]
            );
            // below line just recreates the viewValue - we could implement a moment->moment comparison instead if needed
            //const dateFromValue = getDateValueForConfigEnum(filterItem.dateType, filter.value);

            return dateFromObject === viewValue;
          }

          return p[filterItem.objectProperty].toString().trim() === viewValue.toString();
        } else if (!viewValue) {
          // for extra comparison safety
          return areFalseyEqual(p[filterItem.objectProperty], viewValue);
        }

        return false;
      });
    });

    this.dataSourceUpdated.emit(filteredItems);
  }

  /**
   * Event occurs when an item in the mat-autocomplete is selected.
   * During the filter process the first time the select occurs
   * the else will run setting the associated filtertype record
   * (slump, description, etc..) to active. The second
   * time the if will run setting the final filter item in an
   * array and calling setDataSource.
   */
  public selected(event: MatAutocompleteSelectedEvent): void {
    const viewValue = event.option.viewValue.trim();
    const { objectProperty } = event.option.value;
    const activeFilterExists =
      this.activeFilter &&
      !this.searchableFilters?.some((filter) => filter.objectProperty === objectProperty);

    if (activeFilterExists) {
      const category = this.activeFilter.name;
      const value = event.option.value[this.activeFilter.objectProperty];
      this.selectedFilters.push(new SelectedFilter(category, viewValue, value));
      this.filterInput.nativeElement.value = '';
      this.autocompleteFilterForm.get('filter').setValue('');
      this.activeFilter = undefined;
      this.setDataSource();
    } else {
      const reducedResults = this.searchableFilters.filter(
        (x) => x.name.toLowerCase() === viewValue.toLowerCase()
      );

      if (reducedResults.length === 1) {
        this.filterInput.nativeElement.value = reducedResults[0].name + ': ';
        this.activeFilter = this.searchableFilters.find((x) => x.id === reducedResults[0].id);
      } else {
        console.warn(
          `DEV: You did something wrong - result opts filter returned more than one value ${reducedResults}`
        );
      }
    }
  }

  // not used
  public getItemDescription(item: SearchableFiltersGeneric | any): string | number {
    if (item instanceof SearchableFiltersGeneric) {
      return item.name;
    } else {
      return item[this.activeFilter.objectProperty];
    }
  }

  /**
   * Filters the options the user will see in the autocomplete. If the value for a field
   * after a user has selected a filter category (City, Slump, etc..) is
   * null/undefined it will be returned as an empty string.
   *
   * Due to this the logic that filters the data source (`setDataSource`) will consider all
   * falsey values equal.
   *
   * @param value If the user is typing it will be a string.
   * If the user selects a filter Category it will be SearchableFiltersGeneric.
   * If the user selects a filter value it will be an object and will exit out the default case ['']
   */
  private filter(value: string | SearchableFiltersGeneric): ChipListFilter {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      // if the user types after selecting the field there will be a colon in the value
      if (value.includes(':')) {
        const splitValue = value.split(':');
        const category = splitValue[0].toLowerCase();
        const categoryValue = splitValue[1].trim();
        const matchingFilter = this.searchableFilters.find(
          (x) => x.name.toLowerCase() === category
        );

        if (matchingFilter) {
          const arr = this.masterDataSet.filter((p: IChiplistObject) => {
            if (p[matchingFilter.objectProperty]) {
              return p[matchingFilter.objectProperty]
                .toString()
                .trim()
                .toLowerCase()
                .includes(categoryValue.toLowerCase());
            }

            return false;
          });

          return this.removeDuplicatesBy(matchingFilter, arr);
        } else {
          return [];
        }
      }

      // else we filter just the filter options by the typed in value
      return this.searchableFilters.filter(
        (filter) => filter.name.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      if (value && value.objectProperty) {
        setTimeout(() => {
          this.autoComplete.openPanel();
        });
        // filter for unique filter options after a filter type is selected by
        // getting unique items based on property
        return this.removeDuplicatesBy(value, this.masterDataSet);
      } else {
        return [''];
      }
    }
  }

  private removeDuplicatesBy(
    currentFilter: SearchableFiltersGeneric,
    array: Array<IChiplistObject>
  ): Array<IChiplistObject> {
    const mySet = new Set();
    return array.filter((x) => {
      let value = x[currentFilter.objectProperty];

      if (currentFilter.dateType !== LookupConfigDateType.None) {
        value = getDateValueForConfigEnum(currentFilter.dateType, value);
      } else if (value === '') {
        value = null;
      }

      const isNew = !mySet.has(value);

      if (isNew) {
        mySet.add(value);
      }

      return isNew;
    });
  }
}
