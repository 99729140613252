export class ProductType {
  id: number;
  description: string;
  object: string;

  constructor() {}
}

export enum ProductTypeIds {
  Concrete = 1,
  Aggregate = 2,
  Block = 3,
  Part = 4,
  Other = 5,
  RawMaterial = 6
}
