import moment from 'moment';

export const truckGraphTypes = [
  {
    name: 'Stacked Area',
    id: 'stackedArea'
  },
  {
    name: 'Stacked Step',
    id: 'bar'
  },
  {
    name: 'Area',
    id: 'area'
  },
  {
    name: 'Step',
    id: 'step'
  },
  {
    name: 'Line',
    id: 'line'
  }
];

export const xAxisOptions = [
  {
    name: `24 Hour Format: ${moment().format('HH') ? moment().format('HH') + ':00' : '08:00'}`,
    id: '24Hours'
  },
  {
    name: `12 Hour Format: ${moment().format('ha') || '8am'}`,
    id: '12Hours'
  }
];

export const INTERVAL_SETTING_OPTIONS = [
  {
    name: '15',
    id: 15
  },
  {
    name: '30',
    id: 30
  },
  {
    name: '45',
    id: 45
  },
  {
    name: '60',
    id: 60
  },
  {
    name: '90',
    id: 90
  },
  {
    name: '120',
    id: 120
  }
];
