<form class="au-row" [formGroup]="autocompleteFilterForm">
  <mat-form-field class="col">
    <mat-chip-grid #chipList class="w-100">
      <mat-chip-row *ngFor="let filter of selectedFilters" [selectable]="false" [removable]="true" (removed)="remove(filter)">
        {{ filter }}
        <button matChipRemove>
          <mat-icon color="warn" fontIcon="fa-times"></mat-icon>
        </button>

      </mat-chip-row>
      <input placeholder="Filter (e.g. Description: 3/8 Pea Gravel)"
             #filterInput
             formControlName="filter"
             [matChipInputFor]="chipList"
             [matAutocomplete]="productFilterAutocomplete"
             [matChipInputAddOnBlur]="false"
             [acsVVPHeightCalc]="viewport">
      <mat-autocomplete #productFilterAutocomplete="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
        <cdk-virtual-scroll-viewport #viewport itemSize="45">
          <mat-option *cdkVirtualFor="let item of filteredCategories | async" [value]="item">
            {{getItemFormula(item)}}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
      <!-- for quote slider toggle -->
      <ng-content></ng-content>
    </mat-chip-grid>
  </mat-form-field>
</form>
