export enum SearchToggleEnum {
  active,
  inactive,
  all
}

export class SearchToggle {
  toggleStatus: SearchToggleEnum;

  get status(): boolean {
    if (this.toggleStatus === SearchToggleEnum.active) {
      return false;
    } else if (this.toggleStatus === SearchToggleEnum.inactive) {
      return true;
    } else {
      return undefined;
    }
  }

  constructor(status: SearchToggleEnum) {
    this.toggleStatus = status;
  }
}
