<button
  mat-button
  (click)="cancel()"
  class="row-button flex-center-contents"
  *ngIf="params.context.componentParent.showRowInEditMode(params.node)"
  color="warn">
  <mat-icon class="fa-close"></mat-icon>
</button>
<button
  *ngIf="params.context.componentParent.showRowInEditMode(params.node)"
  mat-button
  color="primary"
  (click)="setRowSelection($event)"
  class="row-button flex-center-contents"
  [disabled]="isButtonDisabled()">
  <mat-icon class="fa-check"> </mat-icon>
</button>

<button
  *ngIf="params.context.componentParent.showRowInViewMode(params.node) && params.context.componentParent.loadingNode !== params.node.id"
  mat-button
  (click)="setRowSelection($event)"
  [disabled]="isButtonDisabled()"
  class="row-button edit-row-button flex-center-contents">
  <mat-icon class="fa-pencil"> </mat-icon>
</button>
<em *ngIf="params.context.componentParent.loadingNode === params.node.id">
  Loading...
</em>