import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { CellValueChangedEvent } from 'ag-grid-community';

export const ALLOW_NEGATIVE_EDITOR_PARAM = 'allowNegative';
/**
 * Cell editor used for mimicking aujs classic grid actions.
 *
 * A user simply has to have the grid in select mode in order to start
 * typing/modifying the data.
 *
 * This is to be utilized with onCellValueChange to update the form on
 * button press.
 *
 * For some reason in some instances you may need to set `cellDataType: false` for the
 * column def that uses this component is being used. Something with the ag-grid typing
 * system doesn't always like the values.
 *
 */
@Component({
  template: `<input
    #input
    (input)="onInput()"
    (blur)="onBlur()"
    [(ngModel)]="value"
    acsOnlyNumber
    [allowNegative]="allowNegative"
    style="width: 100%;"
  />`
})
export class AgGridNumericUpdateEventEditorComponent implements ICellEditorAngularComp {
  @ViewChild('input', { read: ElementRef, static: false }) cellInput!: ElementRef;
  @Output() cellChanged = new EventEmitter<any>();

  public allowNegative: boolean;
  public value: number;
  public params!: any;
  public gridOptions!: any;
  public initialKeyPress: string | null = null;

  agInit(params: any): void {
    this.params = params;
    this.allowNegative = this.params[ALLOW_NEGATIVE_EDITOR_PARAM];
    this.value = params.value;
    this.gridOptions = params.context.componentParent.gridOptions;

    this.initialKeyPress = params.eventKey || null;
    if (this.initialKeyPress && isNaN(Number(this.initialKeyPress))) {
      this.initialKeyPress = null;
    }
  }

  afterGuiAttached(): void {
    setTimeout(() => {
      const { nativeElement } = this.cellInput;
      nativeElement.focus();

      if (this.initialKeyPress !== null) {
        this.value = +this.initialKeyPress;
        this.emitCellValueChanged();
      }
    });
  }

  getValue(): any {
    return this.value;
  }

  onBlur(): void {
    this.params.stopEditing();
  }

  onInput(): void {
    this.emitCellValueChanged();
  }

  private emitCellValueChanged(): void {
    if (!this.gridOptions?.onCellValueChanged) {
      return;
    }

    const event: CellValueChangedEvent = {
      ...this.params,
      oldValue: this.params.value,
      newValue: this.value,
      fromEditorComponent: true
    };

    this.gridOptions.onCellValueChanged(event);
  }
}
