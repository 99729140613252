import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'acs-ag-grid-cell-edit-inline',
  styles: [
    '.edit-row-button {color: lightgray}',
    '.edit-row-button:hover {color: #23b7e5}',
    '.row-button > mat-icon { margin: 0; }',
    '.mat-mdc-button { width: 100%; }'
  ],
  templateUrl: './ag-grid-cell-edit.component.html'
})
export class AgGridCellEditInlineComponent implements AgRendererComponent {
  public params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(): boolean {
    return false;
  }

  public setRowSelection(ev): void {
    ev.stopPropagation();
    if (this.params.node) {
      this.params.context.componentParent.validateAndSelectRowForEditing(this.params.node);
    }
  }

  public cancel(): void {
    if (this.params.node) {
      this.params.context.componentParent.cancelRowSelection(this.params.node);
    }
  }

  public isButtonDisabled(): boolean {
    return this.params.context.componentParent.isEditingDisabled(this.params.node);
  }
}
