import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'acs-expandable-panel',
  templateUrl: './expandable-panel.component.html'
})
export class ExpandablePanelComponent implements OnInit {
  @Input() collapsible: boolean;
  @Input() showSettings: boolean;
  @Input() autoHeight: boolean;
  @Input() tooltip: string;
  @Output() stateChanged = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    if (this.collapsible === undefined) {
      this.collapsible = true;
    }

    if (this.showSettings === undefined) {
      this.showSettings = false;
    }
  }

  public toggleOpenState(): void {
    this.showSettings = !this.showSettings;
    this.stateChanged.emit(this.showSettings);
  }
}
