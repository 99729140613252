import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'acs-ag-grid-cell-order-num',
  styles: [':host {display: flex;}'],
  template: `
    {{ params.value }}
    <mat-icon
      class="fa fa-circle m-1 ml-auto"
      [ngStyle]="{ color: iconColor ? iconColor : 'transparent' }"
    ></mat-icon>
  `
})
export class AgGridCellOrderNumComponent implements AgRendererComponent {
  public params: ICellRendererParams;
  public iconColor: string;

  constructor(private colorService: ColorService) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;

    let colorVal = 0;
    if (this.params.data.orderFlagId) {
      colorVal = this.params.data.orderFlagId;
    } else if (this.params.data.orderColorId) {
      colorVal = this.params.data.orderColorId;
    }

    const color = this.colorService.getColor(colorVal);

    if (color) {
      this.iconColor = this.colorService.getColor(colorVal).hex;
    }
  }

  public refresh(): boolean {
    return false;
  }
}
