<mat-select
  *ngIf="params.context.componentParent.showRowInEditMode(params.node)"
  [(ngModel)]="selectedSymbolId"
  (ngModelChange)="setIconFromSymbol(selectedSymbol)">
  <mat-option
    class="p-0 symbol-option"
    *ngFor="let orderStatus of orderStatuses; trackBy: getIdentifier"
    [value]="orderStatus.id">
    <fa-icon class="col-3" [icon]="getIconFromSymbol(orderStatus.symbol)"></fa-icon>
    <span class="flex-fill ml-2">{{ orderStatus.description }}</span>
  </mat-option>
</mat-select>

<fa-icon
  *ngIf="params.context.componentParent.showRowInViewMode(params.node)"
  [icon]="icon"
  [matTooltip]="tooltip"
  [ngStyle]="{'color': getColor(params)}">
</fa-icon>

