import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class UnshippedOrder {
  address1: string;
  address3: string;
  customerName: string;
  customerNumber: number;
  description: string;
  dispatchNotes: string;
  id: number;
  object: string;
  orderFlagId: number;
  orderNumber: number;
  plantId: number;
  quantity: number;
  scheduleId: number;
  symbol: string;

  private _firstLoadOnJobTime: Moment;
  get firstLoadOnJobTime(): Moment {
    return this._firstLoadOnJobTime;
  }
  set firstLoadOnJobTime(val) {
    this._firstLoadOnJobTime = convertIso8601ToDate(val);
  }

  constructor() {}
}
