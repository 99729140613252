import { LssUiConfig } from '@app/truck-tracking/ui-config/lss-ui-config.model';

export const LoadSchedulerUiConfig: Array<LssUiConfig[]> = [
  [
    {
      id: 'ordersInProgress',
      name: 'Orders In Progress',
      hide: false,
      width: 250,
      height: 250
    }
  ],
  [
    {
      id: 'loadScheduler',
      name: 'Load Scheduler',
      hide: false,
      width: 250,
      height: 250
    },
    {
      id: 'unshippedOrders',
      name: 'Unshipped Orders',
      hide: false,
      width: 250,
      height: 250
    }
  ],
  [
    {
      id: 'trucksInYard',
      name: 'Trucks In Yard',
      hide: false,
      width: 250,
      height: 250
    },
    {
      id: 'completedOrders',
      name: 'Completed Orders',
      hide: false,
      width: 250,
      height: 250
    }
  ],
  [
    {
      id: 'outOfService',
      name: 'Out of Service',
      hide: false,
      width: 250,
      height: 250
    },
    {
      id: 'returningTrucks',
      name: 'Returning Trucks',
      hide: false,
      width: 250,
      height: 250
    }
  ]
];
