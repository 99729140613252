import { IReportSettingsManager } from '@app/reports/report-settings/report-settings-manager.interface';
import { IReportSetting } from '@app/reports/report-settings/report-setting.interface';
import { Observable, forkJoin } from 'rxjs';
import { Setting } from '@app/reports/report-settings/setting.model';

export class ReportSettingsManager implements IReportSettingsManager {
  public settingIds: Array<string>;
  forms?: Setting;

  constructor() {
    this.settingIds = [];
  }

  /*
   * Report Settings object;
   */
  public addSetting(setting: IReportSetting): boolean {
    if (this.settingIds.includes(setting.id) === true) {
      return false;
    }
    this.settingIds.push(setting.id);
    this[setting.id] = setting;

    return true;
  }

  public clean(): void {
    [...this.settingIds].forEach((settingId) => {
      this.removeSetting(settingId);
    });
  }

  public getSettings(): Array<IReportSetting> {
    return this.settingIds.map((x: string) => this[x]);
  }

  public count(): number {
    return this.settingIds.length;
  }

  public getSettingIds(): any {
    return this.settingIds;
  }

  public merge(otherSettings: IReportSettingsManager, overwriteExistingSettings: boolean): boolean {
    otherSettings.settingIds.map((settingId) => {
      if (this.settingIds.includes(settingId) && !overwriteExistingSettings) {
        return false;
      } else {
        if (!this.settingIds.includes(settingId)) {
          this.settingIds.push(settingId);
        }
      }

      this[settingId] = otherSettings[settingId];
    });

    return true;
  }

  public removeSetting(settingId: string): boolean {
    const index = this.settingIds.indexOf(settingId);
    if (index < 0) {
      return false;
    }

    this.settingIds.splice(index, 1);
    delete this[settingId];

    return true;
  }

  /**
   * Creates an array of observables for each setting object
   * based on their `whenReady()` function. This should only be used
   * if the order of the observables doesn't matter and performance
   * is preferable! If the contents of a setting relies on another
   * do not use this. Instead manually chain observables using a
   * combination operator that respects order (switchMap, concat...)
   */
  public whenAllSettingsReady(): Observable<any> {
    const readyObservable = [];

    this.settingIds.forEach((settingId) => {
      if (
        typeof this[settingId].whenReady !== 'undefined' &&
        this[settingId].whenReady !== null &&
        !this[settingId].initialized
      ) {
        this[settingId].initialized = true;
        readyObservable.push(this[settingId].whenReady());
      }
    });

    return forkJoin(readyObservable);
  }
}
