import { IDivision } from '@app/divisions/shared/division.interface';

export class Division implements IDivision {
  id: number;
  abbreviation: string;
  description: string;

  constructor(id?: number, abbreviation?: string, description?: string) {
    this.id = id;
    this.abbreviation = abbreviation;
    this.description = description;
  }
}
