import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Logger } from '@app/logger/logger';

const FILE_INPUT_TYPES = ['.txt', '.sql'] as const;

type IconPosition = 'after' | 'before' | 'none';
export type FileInputTypes = typeof FILE_INPUT_TYPES[number];

@Component({
  selector: 'acs-file-input',
  templateUrl: './file-input.component.html'
})
export class FileInputComponent implements OnInit, AfterViewInit {
  @ViewChild('acsFileInputComponentElement') fileInputRef: ElementRef<HTMLInputElement>;

  @Input() allowMultiple = false;
  @Input() buttonText = 'Upload';
  @Input() readText = true;
  @Input() iconPosition: IconPosition = 'before';
  @Input() fileTypes: FileInputTypes[];

  @Output() fileOutput = new EventEmitter<string | FileList>();

  private fileInput: HTMLInputElement;
  private fileReader: FileReader;

  constructor(private logger: Logger) {}

  ngOnInit(): void {
    this.initializeFileTypes();
  }

  ngAfterViewInit(): void {
    this.setFileReaderLogic();
    this.addEventListener();
  }

  public standInClick(): void {
    this.fileInput.value = null;
    this.fileInput.dispatchEvent(new MouseEvent('click'));
  }

  private initializeFileTypes(): void {
    if (!this.fileTypes?.length) {
      this.fileTypes = [...FILE_INPUT_TYPES];
    } else {
      this.fileTypes.forEach((type) => {
        if (!FILE_INPUT_TYPES.includes(type)) {
          this.logger.warn(`Type ${type} not valid`);
        }
      });
    }
  }

  private addEventListener(): void {
    this.fileInput = this.fileInputRef.nativeElement;
    this.fileInput.addEventListener('change', () => {
      if (this.fileInput.files.length) {
        if (this.readText) {
          // cannot read multiple at once with file reader api
          this.fileReader.readAsText(this.fileInput.files[0]);
        } else {
          this.fileOutput.emit(this.fileInput.files);
        }
      }
    });
  }

  private setFileReaderLogic(): void {
    this.fileReader = new FileReader();
    this.fileReader.onload = () => {
      this.fileOutput.emit(this.fileReader.result as string);
    };
  }
}
