<form [formGroup]="selectOptionsForm">
  <mat-form-field class="col-sm-12">
    <mat-label>{{fieldPlaceholder}}</mat-label>
    <mat-select [required]="isRequired" formControlName="modelOptions" multiple (openedChange)="selectOpenedChange($event)">
      <mat-select-trigger>{{ selectOptionsForm.get('modelOptions').value | checkboxMultiselectPipe: inputOptions }}</mat-select-trigger>
      <mat-option #allSelectionElement (click)="toggleAllSelection()" [value]="0">{{
        selectAllLabel
        }}</mat-option>
      <ng-container *ngFor="let option of inputOptions;">
        <mat-option
          *ngIf="option.msoIsVisible"
          [value]="option"
          (click)="toggleOption()">
          {{ option.name ? option.name : option.msoDescriptor }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</form>
