import { Grouping } from '@app/reports/report-settings/grouping.model';
import { IReportSetting, SettingType } from '@app/reports/report-settings/report-setting.interface';
import { Observable } from 'rxjs';

export class Setting<TOption extends ISettingOption = any> implements IReportSetting<TOption> {
  public id: string;
  public configs?: any;
  public options: Array<TOption>;
  public optionIdProperty?: string;
  public title: string;
  public type: SettingType;
  public value: any;
  public showSystemType?: string;
  public refresh?: any;
  public required?: string;
  public filter?: any;
  public whenReady?: Function;
  public getAppSettingValue: any;
  public loadAppSettingValue: any;
  public optionGroupings?: Grouping[];
  public applyFilters: any;
  public showDragDrop?: boolean;
  public showNoneOption = true;
  public showAllOption = true;
  public showOnReportOptionAvailable: boolean;
  public displayTextFunction: Function;
  public initialized?: boolean;
  public dependentSettings?: IReportSetting[];
  public dataObservable: Observable<any>;
  public allowEmpty = false;
  public allowedSystemTypes: number[];

  constructor(parameters: IReportSetting) {
    this.id = parameters.id;
    this.configs = parameters.configs || {};
    this.options = parameters.options;
    this.optionIdProperty = parameters.optionIdProperty;
    this.title = parameters.title;
    this.type = parameters.type;
    this.value = parameters.value;
    this.showSystemType = parameters.showSystemType;
    this.refresh = parameters.refresh;
    this.required = parameters.required;
    this.filter = parameters.filter;
    this.whenReady = parameters.whenReady;
    this.getAppSettingValue = parameters.getAppSettingValue;
    this.loadAppSettingValue = parameters.loadAppSettingValue;
    this.optionGroupings = parameters.optionGroupings;
    this.displayTextFunction = parameters.displayTextFunction;
    this.showOnReportOptionAvailable = parameters.showOnReportOptionAvailable;
    this.dependentSettings = parameters.dependentSettings;
    this.initialized = parameters.initialized;
    this.dataObservable = parameters.dataObservable;
    this.allowEmpty = parameters.allowEmpty;
  }

  public selectAll(): void {
    if (Array.isArray(this.options)) {
      this.options.map((o) => {
        if (typeof o.visible === 'undefined' || o.visible === null || o.visible) {
          o.selected = true;
        }
      });
    }
  }

  public unselectAll(): void {
    if (Array.isArray(this.options)) {
      this.options.map((o) => {
        o.selected = false;
      });
    } else {
      this.value = undefined;
    }
  }

  public selectGroup(groupId: string, value: string): void {
    if (Array.isArray(this.options) === false) {
      return;
    }

    this.options.map((option: any) => {
      if (option[groupId] === value) {
        option.selected = true;
      }
    });
  }

  public getDisplayText(): string {
    if (typeof this.displayTextFunction === 'function') {
      return this.displayTextFunction();
    }

    return undefined;
  }
}

export interface ISettingOption {
  visible?: boolean;
  selected?: boolean;
}
