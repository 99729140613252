import moment from 'moment';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Logger } from '@app/logger/logger';

/**
 * This directive provides the ability to scroll left using the mouse.
 *
 * On mousedown, pageX (the horizontal coordinate of the mouse) and element.offsetLeft are used
 * to determine starting position for scrolling.
 *
 * On mouse move a new current mouse position in relation to the element.offsetLeft
 * and pageX is calculated.
 *
 * The current mouse position minus the starting position determines how far left to scroll.
 * This can be positive or negative depending on which way the user is moving their mouse.
 */
@Directive({
  selector: '[acsHorizontalScroll]'
})
export class HorizontalScrollerDirective implements AfterViewInit, OnDestroy {
  @Input() registerClickAndDrag = false;

  private element: HTMLDivElement;
  private isDown = false;
  private mouseDownTime: number;
  private scrollLeft: number;
  private startingMouseOffset: number;
  private windowCallback: any;

  constructor(private elementRef: ElementRef, private logger: Logger) {
    this.element = this.elementRef.nativeElement as HTMLDivElement;

    if (!this.element) {
      this.logger.warn('Element for directive acsHorizontalScroll was not a div');
      return;
    }
  }

  ngAfterViewInit(): void {
    if (this.element) {
      this.setWheelEventListener();

      if (this.registerClickAndDrag) {
        this.setClickAndDragEventListeners();
      }
    }
  }

  ngOnDestroy(): void {
    this.element.removeAllListeners();
    this.element = undefined;
  }

  private setClickAndDragEventListeners(): void {
    this.windowCallback = this.captureClick.bind(this);

    this.element.addEventListener('mousedown', (event) => {
      this.mouseDownTime = moment().valueOf();
      this.isDown = true;
      this.startingMouseOffset = event.pageX - this.element.offsetLeft;
      this.scrollLeft = this.element.scrollLeft;
    });

    this.element.addEventListener('mousemove', (event) => {
      if (!this.isDown) {
        return;
      }

      event.preventDefault();
      const mouseOffset = event.pageX - this.element.offsetLeft;
      const scrollDistance = (mouseOffset - this.startingMouseOffset) * 2;
      this.element.scrollLeft = this.scrollLeft - scrollDistance;
    });

    this.element.addEventListener('mouseleave', () => {
      this.isDown = false;
    });

    this.element.addEventListener('mouseup', () => {
      this.isDown = false;

      window.addEventListener('click', this.windowCallback, { passive: true });
    });
  }

  private setWheelEventListener(): void {
    this.element.addEventListener(
      'wheel',
      (event: WheelEvent) => {
        if (!event.shiftKey) {
          this.element.scrollBy({ top: 0, left: event.deltaY > 0 ? 100 : -100, behavior: 'auto' });
        }
      },
      { passive: true }
    );
  }

  private captureClick(event: PointerEvent): void {
    const currentTime = moment().valueOf();

    if (currentTime - this.mouseDownTime > 100) {
      event.stopPropagation();
    }

    window.removeEventListener('click', this.windowCallback, true);
  }
}
