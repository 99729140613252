import { Moment } from 'moment';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class BlockPlant {
  nextTicketNumber: number;
  object: string;
  plantId: number;
  plantManager: string;

  private _loadingTime: Moment;
  get loadingTime(): Moment {
    return this._loadingTime;
  }
  set loadingTime(val) {
    this._loadingTime = convertIso8601ToDate(val);
  }

  constructor() {
    this.nextTicketNumber = 1;
    this.object = 'blockPlant';
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
