import { AuthorizationService } from '@app/security/shared/authorization.service';
import { FORM_PERMISSIONS } from '@app/security/shared/form-permission.model';
import { Injectable } from '@angular/core';
import { REPORT_TYPES, ReportType } from '@app/reports/report-types/report-type.model';

@Injectable({ providedIn: 'root' })
export class ReportTypeService {
  constructor(private authorizationService: AuthorizationService) {}

  public getReportTypesForUser(): Array<ReportType> {
    let types: Array<ReportType> = Object.values(REPORT_TYPES);

    types = types.filter((type: ReportType) => {
      const permission = this.authorizationService.getFormPermission(type.formId);
      return permission.permission === FORM_PERMISSIONS.FullWithPrices;
    });

    types.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });

    return types.map((type) => Object.assign({}, type));
  }

  public userCanViewReportType(reportTypeId: string): boolean {
    try {
      const permission = this.authorizationService.getFormPermission(
        REPORT_TYPES[reportTypeId].formId
      );

      return permission.permission === FORM_PERMISSIONS.FullWithPrices;
    } catch (ex) {
      return false;
    }
  }
}
