import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Form } from '@app/forms/form.model';
import { FormService, OpenFormTarget } from '@app/forms/shared/forms.service';
import { Logger } from '@app/logger/logger';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { WindowService } from '@app/shared/services/browser/window.service';
import { MatDialog } from '@angular/material/dialog';
import { YesNoDialogComponent } from '@app/shared/dialogs/yes-no-dialog.component';
import { TranslateWrapperService } from '@app/shared/services/translate-wrapper.service';

@Component({
  selector: 'acs-form-link',
  templateUrl: './form-link.component.html'
})
export class FormLinkComponent implements OnChanges {
  @Input() formId: string;
  @Input() showIcon: boolean;
  @Input() useLongName: boolean;

  public form: Form;
  public displayName: string;
  public specialClassForms = [AUJS_FORMS.FormNgDashboard.enumId, AUJS_FORMS.FormNgReports.enumId];

  constructor(
    private formService: FormService,
    private log: Logger,
    private windowService: WindowService,
    private matDialog: MatDialog,
    private translate: TranslateWrapperService
  ) {}

  ngOnChanges(onChangesObj: SimpleChanges): void {
    if (typeof onChangesObj !== 'undefined') {
      if (hasValue(onChangesObj.formId)) {
        this.form = AUJS_FORMS[onChangesObj.formId.currentValue];

        if (hasValue(onChangesObj.showIcon)) {
          this.showIcon = onChangesObj.showIcon.currentValue;
        }

        // Handle missing forms.
        if (typeof this.form === 'undefined' || this.form === null) {
          if (this.formId !== 'md-menu-divider') {
            this.log.warn('Form not found for link: ' + this.formId);
          }

          this.form = this.formService.getFormWithParams({
            enabled: false,
            id: undefined,
            legacy: true,
            name: this.formId
          });
        }
      }

      if (this.useLongName) {
        this.displayName = this.form.longName;
      } else {
        // this.displayName = this.translate.instant(this.form.translationId) || this.form.name;
        // If there isn't a blank key then the observable will fail and cause initial loading errors
        this.translate.get(this.form.translationId || ' ').subscribe((result: string) => {
          this.displayName = result || this.form.name;
        });
      }
    }
  }

  public openForm(ev: PointerEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
    const currentForm = this.formService.getCurrentForm();

    if (
      !this.windowService.isCurrentWindowPinned() &&
      currentForm &&
      currentForm.isDirty &&
      !this.form.legacy
    ) {
      this.matDialog
        .open(YesNoDialogComponent, {
          data: {
            title: 'Unsaved Changes',
            content: 'There are unsaved changes. How do you want to open the new form?',
            confirmText: 'New Window',
            denyText: 'Current Window',
            includeCancel: true
          },
          width: this.windowService.screenSize.screenWidth < 804 ? '65vw' : '45vw',
          panelClass: ['acs-dialog-non-padded']
        })
        .afterClosed()
        .subscribe((openInNewWindow) => {
          if (openInNewWindow !== undefined) {
            this.formService.openForm(
              this.form,
              openInNewWindow ? OpenFormTarget.NewTab : OpenFormTarget.SameWindow
            );
          }
        });
    } else {
      this.formService.openForm(
        this.form,
        this.windowService.isCurrentWindowPinned() || ev.ctrlKey
          ? OpenFormTarget.NewTab
          : OpenFormTarget.SameWindow
      );
    }
  }
}
