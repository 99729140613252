import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { IMultiSelectItem } from '@app/ui/option-multi-select/multi-select-item.interface';
import { Setting } from '@app/reports/report-settings/setting.model';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@app/shared/shared.module';
import { MatCardModule } from '@angular/material/card';

const DRAG_DROP_THRESHOLD = 10;

@Component({
  selector: 'acs-option-multi-select',
  templateUrl: './option-multi-select.component.html',
  styleUrls: ['./option-multi-select.component.scss'],
  imports: [
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgIf,
    FormsModule,
    NgForOf,
    CdkDropList,
    CdkDrag,
    MatCheckboxModule,
    MatListModule,
    NgClass,
    MatSelectModule,
    SharedModule,
    NgStyle,
    MatCardModule
  ],
  standalone: true
})
export class OptionMultiSelectComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {
  @Input() setting: Setting;
  // inModal is only used when inside a multi-select component, such as truck-demand config settings
  // since it has a spot for visibility configuration, we can conditionally show component/elements by need.
  @Input() inModal: boolean;
  @Input() showAddButton: boolean;
  @Input() singleColumn: boolean;
  @Input() showDragDrop: boolean;
  @Output() itemMovedEvent = new EventEmitter();
  @Output() itemAddEvent = new EventEmitter<string>();

  public available: IMultiSelectItem[] = [];
  public selected: IMultiSelectItem[] = [];
  public searchText = '';
  public selectedSystemTypeGroup: number;
  public systemTypeGroup: any;
  public jumpFocus: string;
  public isTruckType: boolean;
  public getIdentifierForIterableItem = getIdentifierForIterableItem;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    /*
    Handles the initialization of systemTypeGroup options.
    If no systemType Option Grouping is found, the template conditionally displays the systemType mat-select.
    */
    this.systemTypeGroup = this.setting.optionGroupings?.find(
      (group: any) => group.id === 'systemType'
    );

    if (this.systemTypeGroup) {
      // will default to 1 if no settings exist, report-settings should also be setting a default to 1 as well
      this.selectedSystemTypeGroup = this.setting.configs.systemTypeId || 1;
    }

    /*
    This workaround ensures truck types in truck demand display when no systemType options are available.
    Consider a single input for differentiation, requiring further investigation.
    */
    this.isTruckType = this.setting.id === 'truckTypes';
    this.jumpFocus = 'searchText';

    this.updateLists();
  }
  ngAfterViewInit(): void {
    /*
    After settings are loaded and the view is initalized, for a new LSS profile, the below will filter the default
    available list created by the settings so that only concrete options by default are visible
    */
    if (this.selectedSystemTypeGroup && !this.selected.length) {
      this.setting.options.forEach(
        (x) => (x.visible = this.selectedSystemTypeGroup === x.systemType)
      );
      // Ensure the view is updated to reflect the settings applied after it was initialized.
      this.cdr.detectChanges();
    }
  }

  ngDoCheck(): void {
    if (
      JSON.stringify(this.setting.options.filter((x) => x.selected)) !==
      JSON.stringify(this.selected)
    ) {
      this.filterList();
    }
  }

  ngOnChanges(onChangesObj: SimpleChanges): void {
    if (onChangesObj.setting.currentValue !== onChangesObj.setting.previousValue) {
      this.updateLists();
    }
  }

  public onSelectionChange(source: MatSelect) {
    /*
    Handles the initialization of systemTypeGroup options.
    If no systemType Option Grouping is found, the template conditionally displays the select.
    */
    this.setting.options = this.setting.options.map((option) => ({
      ...option,
      visible: option.systemType === source.value
    }));
    /*
    Handles setting the last saved selected value for the systemTypeGroup select
    */
    this.setting.configs.systemTypeId = source.value;

    this.unselectAll();
    this.updateLists();
  }

  public drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.available.forEach((x) => (x.selected = false));
      this.selected.forEach((x) => (x.selected = true));
      this.itemMovedEvent.emit();
    }
  }

  public updateLists(): void {
    this.available = this.setting.options.filter((x) => !x.selected);
    this.selected = this.setting.options.filter((x) => x.selected);
  }

  public addOption(): void {
    this.itemAddEvent.emit(this.searchText);
  }

  public selectAll(): void {
    this.inModal
      ? this.setting.options.map((option: any) => (option.selected = true))
      : this.setting.selectAll();
  }

  public unselectAll(): void {
    this.inModal
      ? this.setting.options.map((option: any) => (option.selected = false))
      : this.setting.unselectAll();
  }

  public filterList(): void {
    this.updateLists();

    if (this.searchText.length > 0) {
      this.available = this.setting.options.filter(
        (x) => !x.selected && x.name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
    this.itemMovedEvent.emit(this.selected);
  }

  public showDragDropSection(): boolean {
    if (this.showDragDrop) {
      return true;
    }
    if (!this.setting.showDragDrop) {
      return this.setting.options.length > DRAG_DROP_THRESHOLD;
    } else {
      return this.setting.options.length > DRAG_DROP_THRESHOLD || this.setting.showDragDrop;
    }
  }

  public clearInput(): void {
    this.searchText = '';
    this.updateLists();
  }

  public selectOption(): void {
    const matchingOption = this.setting.options.find(
      (x) => x.name.toLowerCase() === this.searchText.toLowerCase()
    );
    const filteredResult = this.setting.options.filter((x) =>
      x.name.toLowerCase().includes(this.searchText.toLowerCase())
    );

    if (matchingOption) {
      matchingOption.selected = !matchingOption.selected;
      this.searchText = '';
    } else if (filteredResult.length === 1) {
      filteredResult[0].selected = !filteredResult[0].selected;
      this.searchText = '';
    }

    this.updateLists();
  }
}
