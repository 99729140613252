<form class="au-row" [formGroup]="autocompleteFilterForm">
  <mat-form-field class="col">
    <mat-chip-grid #chipList>
      <mat-chip-row color="primary" *ngFor="let filter of selectedFilters" [selectable]="false" [removable]="true" (removed)="remove(filter)">
        {{ filter.name }} : {{ filter.viewValue }}
        <button matChipRemove>
          <mat-icon color="warn" class="fa-close"></mat-icon>
        </button>
      </mat-chip-row>
      <input
        matInput
        placeholder="{{filterPlaceHolder}}"
        #filterInput
        formControlName="filter"
        [matChipInputFor]="chipList"
        [matAutocomplete]="autocomplete"
        [matChipInputAddOnBlur]="false">
      <mat-autocomplete #autocomplete="matAutocomplete" autoActiveFirstOption (optionSelected)="selected($event)">
        <mat-option *ngFor="let item of filteredCategories | async" [value]="item">
          {{ item | chipListDescription: activeFilter}}
        </mat-option>
      </mat-autocomplete>
      <ng-content></ng-content>
    </mat-chip-grid>
  </mat-form-field>
</form>
