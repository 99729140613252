import { Component, HostBinding, Inject } from '@angular/core';
import { IReportSetting } from '@app/reports/report-settings/report-setting.interface';
import { IReportSettingsManager } from '@app/reports/report-settings/report-settings-manager.interface';
import { Logger } from '@app/logger/logger';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PartialPlant } from '@app/plants/shared/partial-plant.model';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { YesNoDialogComponent } from '@app/shared/dialogs/yes-no-dialog.component';
import { nameof } from '@app/shared/utilities/object.utility';
import { SettingIds } from '@app/reports/report-settings/report-settings.service';

@Component({
  selector: 'acs-settings-dialog',
  styleUrls: ['./settings-dialog.scss'],
  templateUrl: './settings-dialog.html'
})
export class SettingDialogComponent {
  @HostBinding('class.acs-dialog-content-flexed') contentFlexedClass = true;
  public getIdentifierForIterableItem = getIdentifierForIterableItem;
  public settingsModified: boolean;
  public showVisibleHeader = false;
  private initialSettings: IReportSettingsManager;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name: string; settings: IReportSettingsManager },
    private dialogRef: MatDialogRef<SettingDialogComponent>,
    private logger: Logger,
    private cancelPrompt: MatDialog
  ) {
    this.dialogRef.disableClose = true;
    this.checkSettings(data.settings);
    this.defaultName(data.name);
    this.setVisibleHeader(data.settings.settingIds);
    this.initialSettings = this.saveInitialSettings(data.settings);

    this.dialogRef.backdropClick().subscribe(() => {
      this.cancel();
    });
  }

  public cancel(): void {
    if (this.hasChanges()) {
      this.openCancelPrompt()
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.dialogRef.close(this.data.settings);
          } else {
            this.dialogRef.close();
          }
        });
    } else {
      this.dialogRef.close();
    }
  }

  public close(): void {
    this.dialogRef.close(this.data.settings);
  }

  public hasChanges(): boolean {
    if (this.checkVisibleSelectedPlants()) {
      return false;
    } else {
      return (
        JSON.stringify(this.data.settings, this.replacer) !==
        JSON.stringify(this.initialSettings, this.replacer)
      );
    }
  }

  public changeVisible(data: any): void {
    this.data.settings['plants'] = data;
    this.onSettingsChange();
  }

  public onSettingsChange() {
    this.settingsModified =
      JSON.stringify(this.data.settings, this.replacer) !==
      JSON.stringify(this.initialSettings, this.replacer);
  }

  public setThemeColorsVisibility(setting): void {
    if (setting.id === 'displayTrucksInYard') {
      this.data.settings['themeColors'].value.selectedColors.at(-1).visible = setting.value;
    }
    if (
      setting.id === 'themeColors' &&
      this.data.settings.settingIds.includes('displayTrucksInYard')
    ) {
      this.data.settings[SettingIds.themeColors].value.selectedColors.at(
        -1
      ).visible = this.data.settings['displayTrucksInYard'].value;
    }
    this.onSettingsChange();
  }

  public callSettingRefresh(settingId): void {
    this.data.settings[settingId].refresh(true);
    this.onSettingsChange();
  }

  private checkSettings(settings: IReportSettingsManager): void {
    if (!hasValue(settings)) {
      this.logger.warn('Report Settings dialog opened without any settings');
    }
  }

  private defaultName(name: string): void {
    if (!hasValue(name)) {
      name = 'Settings';
    }
  }

  private checkVisibleSelectedPlants(): boolean {
    return (
      this.data.settings.settingIds.includes('plants') &&
      !this.data.settings['plants'].options.some((x: PartialPlant) => x.visible && x.selected)
    );
  }

  private openCancelPrompt(): MatDialogRef<YesNoDialogComponent> {
    return this.cancelPrompt.open(YesNoDialogComponent, {
      data: {
        title: 'Data has changed',
        content: 'Do you want to save before closing?'
      },
      panelClass: ['acs-dialog-non-padded']
    });
  }

  private setVisibleHeader(settingsIds): void {
    settingsIds ? (this.showVisibleHeader = true) : (this.showVisibleHeader = false);
  }

  private saveInitialSettings(settings: IReportSettingsManager): IReportSettingsManager {
    return JSON.parse(JSON.stringify(settings, this.replacer));
  }

  private replacer(_key: string, _value: any) {
    // Filtering out properties
    if (
      _key === nameof<IReportSetting>('dataObservable') ||
      _key === nameof<IReportSetting>('dependentSettings')
    ) {
      return undefined;
    }
    return _value;
  }

  protected readonly SettingIds = SettingIds;
}
