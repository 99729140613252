export interface IReportType {
  id: string;
  name: string;
  formEnumId: string;
  formId: number;
}

export class ReportType implements IReportType {
  id: string;
  name: string;
  formId: number;
  formEnumId: string;

  constructor(params: IReportType) {
    this.id = params.id;
    this.name = params.name;
    this.formEnumId = params.formEnumId;
    this.formId = params.formId;
  }
}

export const REPORT_TYPES = {
  marketSegments: new ReportType({
    formId: 247000,
    formEnumId: 'ReportMarketSegments',
    id: 'marketSegments',
    name: 'Market Segments'
  }),
  materialDemand: new ReportType({
    formId: 242000,
    formEnumId: 'ReportMaterialDemand',
    id: 'materialDemand',
    name: 'Material Demand'
  }),
  salesQuantity: new ReportType({
    formId: 244000,
    formEnumId: 'ReportSalesQuantity',
    id: 'salesQuantity',
    name: 'Sales Quantity'
  }),
  shippedOrders: new ReportType({
    formId: 245000,
    formEnumId: 'ReportShippedOrders',
    id: 'shippedOrders',
    name: 'Shipped Orders'
  }),
  truckDemand: new ReportType({
    formId: 246000,
    formEnumId: 'ReportTruckDemand',
    id: 'truckDemand',
    name: 'Truck Demand'
  }),
  EMPTY: new ReportType({
    formId: -1,
    formEnumId: '',
    id: 'EMPTY',
    name: ''
  })
};
