import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class BatchLoad {
  address1: string;
  address3: string;
  addPlusLoad: boolean;
  customerName: string;
  customerNumber: string;
  customerPoNumber: string;
  deliveredQuantity: number;
  dispatchNotes: string;
  isPublicWorks: number;
  loadIntervalTime: number;
  mapReferenceDescription: string;
  object: string;
  orderColorId: number;
  orderId: number;
  orderLotOrBlockNumber: string;
  orderVerifiedFlag: boolean;
  orderNumber: number;
  // this is first set on the client as the
  // bl records objects are initialized
  orderStatusId: number;
  orderStatusSymbol: string;
  orderedByName: string;
  orderedQuantity: number;
  phoneNumber: string;
  plantColorId: number;
  plantDescription: string;
  plantId: number;
  productId: number;
  productNumber: string;
  pumperName: string;
  salesPersonName: string;
  scheduleId: number;
  shippedLoadCount: number;
  systemTypeId: number;
  travelTimeToJob: number;
  travelTimeToPlant: number;
  ticketTime: number;
  truckType: string;
  truckTypeId: number;
  usageTypeDescription: string;

  private _loadTime: Moment; // datetime NULL,
  get loadTime(): Moment {
    return this._loadTime;
  }
  set loadTime(val) {
    this._loadTime = convertIso8601ToDate(val);
  }

  private _shipDate: Moment; // datetime NULL,
  get shipDate(): Moment {
    return this._shipDate;
  }
  set shipDate(val) {
    this._shipDate = convertIso8601ToDate(val);
  }
}
