import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { OptionMultiSelectComponent } from '@app/ui/option-multi-select/option-multi-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDrag, CdkDropList } from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { IMultiSelectItem } from '@app/ui/option-multi-select/multi-select-item.interface';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { Setting } from '@app/reports/report-settings/setting.model';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'acs-option-multi-select-dialog',
  templateUrl: './option-multi-select-dialog.component.html',
  styleUrls: ['../option-multi-select/option-multi-select.component.scss'],
  standalone: true,
  imports: [
    CdkDropList,
    NgFor,
    CdkDrag,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    OptionMultiSelectComponent,
    NgIf,
    FormsModule,
    SharedModule,
    MatCardModule
  ]
})
export class OptionMultiSelectDialogComponent implements OnInit {
  @Output() itemMovedEvent = new EventEmitter();
  @Output() itemAddEvent = new EventEmitter<string>();

  public searchText = '';
  public jumpFocus: string;
  public available: IMultiSelectItem[] = [];
  public selected: IMultiSelectItem[] = [];
  public disableOk: boolean;
  public localSetting: Setting;
  public singleColumn: boolean;
  public showDragDrop: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { title: string; setting: Setting; showDragDrop: boolean },
    private dialogRef: MatDialogRef<OptionMultiSelectDialogComponent>
  ) {}

  ngOnInit() {
    this.showDragDrop = this.data.showDragDrop;
    this.localSetting = new Setting(this.data.setting);
    this.updateLists();
    this.jumpFocus = 'searchText';
  }

  public onOkayClick(): void {
    this.updateLists();
    this.dialogRef.close(this.selected);
  }

  public updateLists(): void {
    this.available = this.localSetting.options.filter((x) => !x.selected);
    this.selected = this.localSetting.options.filter((x) => x.selected);
    this.disableOk =
      (this.selected.length === 0 && !this.data.setting.allowEmpty) ||
      (this.selected.length === 0 && this.data['minLimitSave']);
    this.singleColumn = this.data['singleColumn'];
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
