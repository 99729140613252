<div class="d-flex flex-column w-100">
  <div mat-dialog-content class="p-3 border rounded w-100" style="max-height: 350px; overflow-y: auto;">
    <div class="row">
      <div class="col-sm-6" *ngFor="let option of filteredOptions;">
        <p>
          <mat-checkbox color="primary" [(ngModel)]="option.visible" (change)="visibilityUpdated()">{{ option.name }}</mat-checkbox>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!alignButtonsTop" class="d-flex justify-content-between mt-3">
    <div class="d-flex">
      <button mat-raised-button class="md-button m-2" (click)="selectAll()">All</button>
      <button mat-raised-button class="md-button m-2" (click)="unselectAll()">None</button>
    </div>
    <div>
      <button mat-raised-button class="md-button m-2" (click)="showDisplayDialog()" type="button" color="primary">Config</button>
    </div>
  </div>
</div>
