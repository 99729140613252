import { IAppSetting } from '@app/settings/shared/setting.interface';

export class AppSetting<T> implements IAppSetting<T> {
  id: string;
  data: T;

  constructor(id?: string, data?: T) {
    this.id = id;
    this.data = data;
  }

  public isValid(): boolean {
    return (
      this.id.length <= 256
      // * removed additional check in place of backend compression/checks 3/22/2021
      // (typeof this.data === 'undefined' || JSON.stringify(this.data).length <= 4096)
    );
  }
}
