import { AgRendererComponent } from 'ag-grid-angular';
import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

/**
 * A reusable component to display edit/remove buttons in a single column
 * in a grid. The parent component must implement the interface IAgGridEditButtonsComponent
 * to work properly.
 *
 * This differs from the component `AgGridCellEditInlineComponent` by not needing to perform row selection
 * on the grid - in other words this component should not be used for inline editing but when edit/delete
 * actions should be performed elsewhere (such as in a dialog).
 */
@Component({
  selector: 'acs-ag-grid-edit-buttons',
  styleUrls: ['./ag-grid-cell-edit-buttons.component.scss'],
  templateUrl: './ag-grid-cell-edit-buttons.component.html'
})
export class AgGridCellEditButtonsComponent implements AgRendererComponent {
  public componentParent: IAgGridEditButtonsComponent;
  public params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    if (!isIAgGridEditButtonsComponent(params.context.componentParent)) {
      throw new Error('Parent component does not impelement required interface');
    }

    this.params = params;
    this.componentParent = this.params.context.componentParent as IAgGridEditButtonsComponent;
  }

  public refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.componentParent = this.params.context.componentParent as IAgGridEditButtonsComponent;
    return true;
  }

  public edit(): void {
    this.componentParent.editClick(this.params.data.id);
  }

  public remove(): void {
    this.componentParent.verifyDelete(this.params.data.id);
  }
}

export interface IAgGridEditButtonsComponent {
  editClick(id: number): void;
  verifyDelete(id: number): void;
}

const isIAgGridEditButtonsComponent = (item: any): item is IAgGridEditButtonsComponent =>
  'editClick' in item && 'verifyDelete' in item;
