import moment from 'moment';
import { AppConfig } from '@app/app.config';
import { FORM_ID_HEADER } from '@app/shared/constants/constants';
import { HttpOptionsService, HttpService } from '@app/security/shared/http.service';
import { HttpParams } from '@angular/common/http';
import { IMaterial } from '@app/reports/material-demand/material.model';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Material } from '@app/reports/material-demand/material.model';
import { Observable } from 'rxjs';
import { REPORT_TYPES } from '@app/reports/report-types/report-type.model';
import { map, share } from 'rxjs/operators';

@Injectable()
export class MaterialDemandReportService {
  public materials = Array<IMaterial>();

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private optionsService: HttpOptionsService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public getData(dateSetting: any, plantsSetting: any, materialsSetting: any): Observable<any> {
    let params = new HttpParams();

    if (dateSetting.value) {
      const formattedDate = moment(dateSetting.value).local().format('YYYY-MM-DD');
      params = params.set('shipDate', formattedDate);
    }
    if (plantsSetting) {
      const selectedPlants = plantsSetting.options.filter((option) => option.selected === true);
      params = params.set('plantIds', selectedPlants.map((plant) => plant.id).join(','));
    }
    if (materialsSetting) {
      const productIds =
        (materialsSetting && materialsSetting.value && materialsSetting.value.id) || '';
      params = params.set('productIds', productIds);
    }

    const API_ENDPOINT = '/api/MaterialDemand';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + '?' + params.toString();

    const options = this.optionsService.get();
    options.headers = options.headers.set(
      FORM_ID_HEADER,
      REPORT_TYPES.materialDemand.formId.toString()
    );

    return this.http.getAny(endpoint, options);
  }

  public getMaterials(plantIds: Array<string | number>): Observable<Array<IMaterial>> {
    this.log.log(`Loading material from the server \t(materials-demand-report.service)`);
    let params = new HttpParams();
    params = params.set('plantIds', plantIds.join(','));

    const API_ENDPOINT = '/api/materialdemand';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + '?' + params.toString();

    const options = this.optionsService.get();
    options.headers = options.headers.set(
      FORM_ID_HEADER,
      REPORT_TYPES.materialDemand.formId.toString()
    );

    return this.http.getAny(endpoint, options).pipe(
      share(),
      map((materialResp) => {
        this.materials = materialResp.map(
          (material) =>
            new Material(material.Id, material.Description, material.Number, material.ProductTypeId)
        );
        return this.materials;
      })
    );
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.materials = Array<IMaterial>();
  }
}
