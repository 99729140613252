<mat-select
  *ngIf="params.context.componentParent.showRowInEditMode(params.node)"
  [(ngModel)]="selectedPlant"
  (ngModelChange)="setPlantColorId()">
  <mat-option *ngFor="let plant of plants; trackBy: getIdentifier" [value]="plant.id">
    {{ plant.name }}
  </mat-option>
</mat-select>
<span *ngIf="params.context.componentParent.showRowInViewMode(params.node)">{{
  params.value
}}</span>
<mat-icon
  class="fa fa-circle m-1 ml-auto pl-1"
  [ngStyle]="{ color: iconColor }"
  *ngIf="params.context.componentParent.showRowInViewMode(params.node)">
</mat-icon>
