<button
  mat-button
  (click)="remove()"
  class="remove-button"
  type="button"
  color="warn">
  <mat-icon class="fa fa-close"></mat-icon>
</button>


<button
  mat-button
  (click)="edit()"
  color="primary"
  type="button">
  <mat-icon class="fa fa-pencil"></mat-icon>
</button>
