// autocomplete-editor.component.ts
import { AfterViewInit, Component, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { FormControl } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const AG_GRID_POPUP_CLASS = 'ag-custom-component-popup';

@Component({
  selector: 'acs-autocomplete-cell',
  template: `
    <input
      style="width: 100%;"
      type="text"
      matInput
      #cellInput
      [formControl]="cellControl"
      [matAutocomplete]="cellAutocomplete"
      #autoCompleteTrigger="matAutocompleteTrigger"
      [acsVVPHeightCalc]="viewport"
    />
    <mat-autocomplete
      autoActiveFirstOption
      #cellAutocomplete="matAutocomplete"
      (optionSelected)="onOptionSelected($event)"
    >
      <cdk-virtual-scroll-viewport #viewport itemSize="45">
        <mat-option *cdkVirtualFor="let option of filteredOptions | async" [value]="option">
          {{ option[displayField] }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  `,
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: AG_GRID_POPUP_CLASS }
    }
  ]
})
export class AgGridAutocompleteEditorComponent
  implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  @ViewChild('autoCompleteTrigger', { static: false })
  autoCompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('cellInput', { read: ViewContainerRef }) public cellInput: ViewContainerRef;

  private params: any;
  public cellControl = new FormControl();
  public filteredOptions: Observable<(string | number)[]>;
  public options: any[] = []; // This will be set from the params.
  public displayField: string;

  ngOnDestroy(): void {
    console.log('AgGridAutocompleteEditorComponent destroyed!');
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.options = this.params.options || [];
    this.displayField = this.params.accessProperty;

    if (this.params.value) {
      this.cellControl.setValue(this.params.value);
    }

    this.filteredOptions = this.cellControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (typeof value === 'string') {
          return this._filter(value);
        }

        return this.options;
      })
    );
  }

  getValue(): any {
    return this.cellControl.value;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cellInput.element.nativeElement.focus();
      this.autoCompleteTrigger.closePanel();
    });
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    this.cellControl.setValue(event.option.value, { emitEvent: false });
    this.params.stopEditing();
  }

  private _filter(value: string | number): any[] {
    if (this.params.data.id) {
      return;
    }

    if (!value || value.toString().trim() === '') {
      return this.options;
    }

    const filterValue = value.toString().toLowerCase();
    return this.options.filter((option) =>
      option[this.displayField].toString().toLowerCase().includes(filterValue)
    );
  }
}
