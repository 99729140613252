import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'acs-numeric-cell',
  template: `<input #numInput acsOnlyNumber [(ngModel)]="value" style="width: 100%" />`
})
export class AgGridNumericEditorComponent implements ICellEditorAngularComp {
  @ViewChild('numInput', { read: ViewContainerRef }) public input: ViewContainerRef;

  public value: number;

  private params: any;

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.value = this.params.value;
  }

  getValue(): any {
    return this.value;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    });
  }
}
