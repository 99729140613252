import { IChiplistObject } from '@app/ui/autocomplete-chiplist/generic-autocomplete-chiplist.component';
import { LookupConfigDateType } from '@app/ui/autocomplete-chiplist/lookup-config-date-type.enum';
import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class OrderLookup implements IChiplistObject {
  address1: string;
  address3: string;
  customerName: string;
  customerJobNumber: string;
  id: number;
  jobNumber: number;
  orderForms: number;
  phoneNumber: string;
  plantId: number;
  quoteName: string;
  quoteNumber: number;

  private _firstLoadOnJobTime: Moment; // datetime NOT NULL,
  get firstLoadOnJobTime(): Moment {
    return this._firstLoadOnJobTime;
  }
  set firstLoadOnJobTime(val) {
    this._firstLoadOnJobTime = convertIso8601ToDate(val);
  }

  private _shipDate: Moment;
  get shipDate(): Moment {
    return this._shipDate;
  }
  set shipDate(val) {
    this._shipDate = convertIso8601ToDate(val);
  }
}

// export enum OrderLookupApiFields {
//   Job_Number = 'jobNumber',
//   Address_1 = 'address1',
//   Address_3 = 'address3',
//   Customer_Job_Number = 'customerJobNumber',
//   Customer_Name = 'customerName',
//   Phone_Number = 'phoneNumber',
//   Quote_Name = 'quoteName',
//   Quote_Number = 'quoteNumber',
//   Order_Number = 'orderNumber',
//   Ship_Date = 'shipDate',
//   Start_Time = 'firstLoadOnJobTime'
// }

export type OrderLookupField =
  | 'jobNumber'
  | 'address1'
  | 'address3'
  | 'customerJobNumber'
  | 'customerName'
  | 'phoneNumber'
  | 'quoteName'
  | 'quoteNumber'
  | 'orderNumber'
  | 'shipDate'
  | 'firstLoadOnJobTime'
  | 'orderNumber';

class ApiLookupObject<T> {
  apiField: string;
  clientField: T;
  dateType: LookupConfigDateType;
  displayValue?: string;
}

// rename to config?
export const ORDER_LOOKUP_API_FIELDS: {
  [key in OrderLookupField]: ApiLookupObject<OrderLookupField>;
} = {
  jobNumber: {
    apiField: 'Job_Number',
    clientField: 'jobNumber',
    dateType: LookupConfigDateType.None
  },
  address1: {
    apiField: 'Address_1',
    displayValue: 'Address',
    clientField: 'address1',
    dateType: LookupConfigDateType.None
  },
  address3: {
    apiField: 'Address_3',
    displayValue: 'City',
    clientField: 'address3',
    dateType: LookupConfigDateType.None
  },
  customerJobNumber: {
    apiField: 'Customer_Job_Number',
    clientField: 'customerJobNumber',
    dateType: LookupConfigDateType.None
  },
  customerName: {
    apiField: 'Customer_Name',
    clientField: 'customerName',
    dateType: LookupConfigDateType.None
  },
  phoneNumber: {
    apiField: 'Phone_Number',
    clientField: 'phoneNumber',
    dateType: LookupConfigDateType.None
  },
  quoteName: {
    apiField: 'Quote_Name',
    clientField: 'quoteName',
    dateType: LookupConfigDateType.None
  },
  quoteNumber: {
    apiField: 'Quote_Number',
    clientField: 'quoteNumber',
    dateType: LookupConfigDateType.None
  },
  orderNumber: {
    apiField: 'Order_Number',
    clientField: 'orderNumber',
    dateType: LookupConfigDateType.None
  },
  shipDate: {
    apiField: 'Ship_Date',
    clientField: 'shipDate',
    dateType: LookupConfigDateType.DateOnly
  },
  firstLoadOnJobTime: {
    apiField: 'Start_Time',
    clientField: 'firstLoadOnJobTime',
    dateType: LookupConfigDateType.TimeOnly
  }
};

export const ORDER_LOOKUP_API_MAP = new Map([
  ['jobNumber', ORDER_LOOKUP_API_FIELDS.jobNumber],
  ['address1', ORDER_LOOKUP_API_FIELDS.address1],
  ['address3', ORDER_LOOKUP_API_FIELDS.address3],
  ['customerJobNumber', ORDER_LOOKUP_API_FIELDS.customerJobNumber],
  ['customerName', ORDER_LOOKUP_API_FIELDS.customerName],
  ['phoneNumber', ORDER_LOOKUP_API_FIELDS.phoneNumber],
  ['quoteName', ORDER_LOOKUP_API_FIELDS.quoteName],
  ['quoteNumber', ORDER_LOOKUP_API_FIELDS.quoteNumber],
  ['orderNumber', ORDER_LOOKUP_API_FIELDS.orderNumber],
  ['shipDate', ORDER_LOOKUP_API_FIELDS.shipDate],
  ['firstLoadOnJobTime', ORDER_LOOKUP_API_FIELDS.firstLoadOnJobTime]
]);
