import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
  OnInit,
  OnChanges,
  forwardRef
} from '@angular/core';
import { Logger } from '@app/logger/logger';
import { MatOption } from '@angular/material/core';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';

/**
 * This component provides a custom implementation of a multi-select mat-select.
 * It provides Select All/Deselect All capabilities and can be used with any type
 * as long as the type implements ICheckboxMultiSelectOption.
 *
 * The component also implements ControlValueAccessor and so can also be used with
 * angular template or reactive forms. This is not required. The parent component can
 * instead use the Output `selectedOptionsChange` to receive data.
 *
 * @param inputOptions List of objects that will build the select list. Should implement
 * the interface ICheckboxMultiSelectOption and be an actual instance of the underlying
 * concrete type otherwise the fields defined by the interface won't be accessible.
 *
 * @param selectedOptions should be a list of ids that each correspond to the msoKey field of the
 * matching object in the inputOptions array.
 *
 * @note getIdentifierForIterableItem was used in this components template. However it was causing
 * incorrect rebuilds of the select options. This was an issue during development for #2052. For now
 * we have removed it. If we need it in the future we may need to provide a custom implementation through
 * an @Input() to override the default.
 */
@Component({
  selector: 'acs-checkbox-multi-select',
  templateUrl: './checkbox-multi-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxMultiSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckBoxMultiSelectComponent),
      multi: true
    }
  ]
})
export class CheckBoxMultiSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor, Validator {
  @ViewChild('allSelectionElement') private allSelectionElement: MatOption;

  @Input() emitOnClose = false;
  @Input() fieldPlaceholder = 'Select Option';
  @Input() inputOptions: Array<ICheckboxMultiSelectOption>;
  @Input() isRequired = false;
  @Input() selectedOptions: Array<any>;
  @Input() selectAllLabel = 'Select/Deselect All';

  @Output() selectedOptionsChange: EventEmitter<any[]> = new EventEmitter();

  public selectOptionsForm: FormGroup;
  public getIdentifierForIterableItem = getIdentifierForIterableItem; // not used

  private get modelOptFormControl(): AbstractControl {
    return this.selectOptionsForm.controls.modelOptions;
  }

  constructor(private formBuilder: FormBuilder, private logger: Logger) {
    this.selectOptionsForm = this.formBuilder.group({
      modelOptions: new FormControl({ value: '', disabled: true })
    });
  }

  ngOnInit(): void {
    this.inputOptions = this.inputOptions?.filter((data) => data.msoKey !== 'NULL');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.selectedOptions?.currentValue) {
      this.selectedOptionsChange.emit([]);
      this.modelOptFormControl.patchValue([]);
    } else if (
      changes.selectedOptions &&
      changes.selectedOptions.currentValue !== changes.selectedOptions.previousValue
    ) {
      const matchedOptions = this.inputOptions?.filter((option) =>
        this.selectedOptions?.includes(option.msoKey)
      );

      const visibleInputOptions = this.inputOptions?.filter((x) => x.msoIsVisible);
      if (
        Array.isArray(visibleInputOptions) &&
        matchedOptions?.length === visibleInputOptions.length
      ) {
        this.modelOptFormControl.patchValue([...visibleInputOptions, 0]);
      } else {
        this.modelOptFormControl.patchValue(matchedOptions);
      }
    }

    this.inputOptions?.length
      ? this.modelOptFormControl.enable()
      : this.modelOptFormControl.disable();
  }

  public toggleAllSelection() {
    if (this.allSelectionElement.selected) {
      this.modelOptFormControl.patchValue([
        ...this.inputOptions.filter((item) => item.msoIsVisible),
        0
      ]);
    } else {
      this.modelOptFormControl.patchValue([]);
    }

    if (!this.emitOnClose) {
      this.sendData(this.modelOptFormControl.value);
    }
  }

  public selectOpenedChange(wasOpened): void {
    if (!this.emitOnClose) {
      return;
    }

    if (!wasOpened) {
      if (this.selectOptionsForm.dirty) {
        this.selectOptionsForm.markAsPristine();
        this.toggleOption(true);
      }
    }
  }

  public toggleOption(emitOverride = false): void {
    if (this.allSelectionElement.selected) {
      this.allSelectionElement.deselect();
    }

    if (this.selectOptionsForm.controls.modelOptions.value.length === this.inputOptions.length) {
      this.allSelectionElement.select();
    }

    if (this.emitOnClose && !emitOverride) {
      return;
    }

    if (this.selectOptionsForm.controls.modelOptions.value.length > this.inputOptions.length) {
      this.sendData(this.selectOptionsForm.controls.modelOptions.value.slice(1));
    } else {
      this.sendData(this.selectOptionsForm.controls.modelOptions.value);
    }
  }

  /**
   * Can be used to set the initial value on the form
   */
  public writeValue(obj: any): void {
    if (obj) {
      this.selectedOptions = obj;
    }
  }

  /**
   * Registers a callback that can be fired when the form control data is changed.
   * It is our responsibility to call `propagateChange` when necessary.
   */
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback that can be fired when the form control is touched/blurred.
   * It is our responsibility to call `onTouched` when necessary.
   */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * A hook into the validation logic of the form control.
   * Implement custom validation logic here.
   */
  public validate(control: AbstractControl<any, any>): ValidationErrors {
    this.logger.log('CheckBoxMultiSelectComponent.validate', control);
    if (this.isRequired && !Boolean(control.value?.length)) {
      return { required: true };
    }

    return null;
  }

  private propagateChange = (_: any) => {};
  private onTouched = () => {};

  private sendData(data): void {
    this.logger.log('CheckBoxMultiSelectComponent.sendData', data);
    this.onTouched();
    this.propagateChange(data.map((x) => x.msoKey));
    this.selectedOptionsChange.emit(data);
  }
}

// should be implemented as getters
export interface ICheckboxMultiSelectOption {
  readonly msoKey: number | string;
  readonly msoDescriptor: string;
  readonly msoIsVisible: boolean;
}
