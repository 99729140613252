import { AgGridAutocompleteEditorComponent } from '@app/ui/ag-grid-cell-editor/ag-grid-autocomplete-editor.component';
import { AgGridCellEditInlineComponent } from '@app/ui/ag-grid-cell-renderer/ag-grid-cell-edit.component';
import { AgGridCellEditButtonsComponent } from '@app/ui/ag-grid-cell-renderer/ag-grid-cell-edit-buttons/ag-grid-cell-edit-buttons.component';
import { AgGridCellOrderNumComponent } from '@app/ui/ag-grid-cell-renderer/ag-grid-cell-order-num.component';
import { AgGridCellPlantComponent } from '@app/ui/ag-grid-cell-renderer/ag-grid-cell-plant.component';
import { AgGridCellSymbolComponent } from '@app/ui/ag-grid-cell-renderer/ag-grid-cell-symbol.component';
import { AgGridNumericEditorComponent } from '@app/ui/ag-grid-cell-editor/ag-grid-numeric-editor.component';
import { AgGridNumericUpdateEventEditorComponent } from '@app/ui/ag-grid-cell-editor/ag-grid-numeric-update-event-editor.component';
import { ProductAutocompleteChiplistComponent } from '@app/ui/autocomplete-chiplist/products/product-autocomplete-chiplist.component';
import { CheckBoxMultiSelectComponent } from '@app/ui/checkbox-multi-select/checkbox-multi-select.component';
import { CheckboxMultiSelectPipe } from '@app/ui/checkbox-multi-select/checkbox-multi-select.pipe';
import { ChipListDescriptionPipe } from '@app/ui/autocomplete-chiplist/autocomplete-chiplist-description.pipe';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpandablePanelComponent } from '@app/ui/expandable-panel/expandable-panel.component';
import { FileInputComponent } from '@app/ui/file-input/file-input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormLinkComponent } from '@app/ui/form-link/form-link.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericAutocompleteChiplistComponent } from '@app/ui//autocomplete-chiplist/generic-autocomplete-chiplist.component';
import { HorizontalScrollerDirective } from '@app/ui/toolbar/horizontal-scroller.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule
} from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { OptionFilterSelectComponent } from '@app/ui/option-filter-select/option-filter-select.component';
import { OptionMultiSelectComponent } from '@app/ui/option-multi-select/option-multi-select.component';
import { SearchToggleComponent } from '@app/ui/search-toggle/search-toggle.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SettingDialogComponent } from '@app/ui/shared/settings-dialog.component';
import { SharedModule } from '@app/shared/shared.module';
import { ToolbarComponent } from '@app/ui/toolbar/toolbar.component';

@NgModule({
  declarations: [
    AgGridNumericUpdateEventEditorComponent,
    CheckBoxMultiSelectComponent,
    CheckboxMultiSelectPipe,
    OptionFilterSelectComponent,
    ExpandablePanelComponent,
    FormLinkComponent,
    GenericAutocompleteChiplistComponent,
    SettingDialogComponent,
    AgGridAutocompleteEditorComponent,
    AgGridCellOrderNumComponent,
    AgGridCellPlantComponent,
    AgGridCellEditInlineComponent,
    AgGridCellSymbolComponent,
    AgGridCellEditButtonsComponent,
    AgGridNumericEditorComponent,
    ToolbarComponent,
    ProductAutocompleteChiplistComponent,
    SearchToggleComponent,
    HorizontalScrollerDirective,
    FileInputComponent
  ],
  imports: [
    ChipListDescriptionPipe,
    CommonModule,
    DragDropModule,
    FormsModule,
    FontAwesomeModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatToolbarModule,
    OptionMultiSelectComponent,
    ScrollingModule,
    SharedModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  exports: [
    AgGridAutocompleteEditorComponent,
    AgGridNumericEditorComponent,
    ExpandablePanelComponent,
    FileInputComponent,
    FormLinkComponent,
    ToolbarComponent,
    OptionMultiSelectComponent,
    ProductAutocompleteChiplistComponent,
    SearchToggleComponent,
    CheckBoxMultiSelectComponent,
    GenericAutocompleteChiplistComponent
  ]
})
export class UIModule {}
