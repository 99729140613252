/**
 * A type safe way to get the names of object keys as strings.
 * Will fail to compile if provided name parameter isn't a key
 * on T.
 *
 * @param name The name of the object key
 * @returns the provided name parameter
 */
export const nameof = <T>(name: keyof T) => name;

/**
 * Util method to perform a deep copy of an object.
 * Warning: Will not copy functions on objects!
 *
 * @param object The object to copy
 * @returns
 */
export const jsonDeepCopy = <T>(object: T) => JSON.parse(JSON.stringify(object));
