import { ICheckboxMultiSelectOption } from '@app/ui/checkbox-multi-select/checkbox-multi-select.component';
import { ISettingOption } from '@app/reports/report-settings/setting.model';

export class PartialPlant implements ICheckboxMultiSelectOption, ISettingOption {
  address1: string;
  colorId: number;
  color: string;
  divisionId: number;
  id: number;
  inactiveFlag: boolean;
  latitude: string;
  longitude: string;
  name: string;
  printTicketMethod: number;
  systemType: number;
  useBatchPanelFlag: boolean;
  // for settings
  selected?: boolean;
  visible?: boolean;
  divisionAbbreviation?: string;
  /**
   * A secondary option for tracking 'selected' state on this plant model.
   * Currently only used in truck demand. For example usage: The user selects
   * a plant in the truck demand settings dialog. Those selected plants are available
   * in the mult-select available on the truck demand widget. When a user selects
   * a plant there this field will be used.
   **/
  selectionConfirmed?: boolean;

  get msoKey(): number {
    return this.id;
  }

  get msoDescriptor(): string {
    return this.name;
  }

  get msoIsVisible(): boolean {
    return true;
  }
}
