<div class="expandable-panel"
     [ngClass]="{ 'opened': showSettings,
               'collapsible': collapsible,
                'autoHeight': autoHeight }">

  <ng-content></ng-content>

  <div class="expand-button" *ngIf="collapsible">
    <button mat-flat-button color="accent" (click)="toggleOpenState()" [matTooltip]="tooltip">
      <mat-icon class="fa fa-caret-down" aria-label="Show panel"></mat-icon>
    </button>
  </div>
</div>
