@if(setting.options.length > 0) {
<section class="row p-2 pt-1">
  <mat-form-field *ngIf="singleColumn ? false : showDragDropSection()" class="col-md-auto col-xs-12 pb-3">
    <mat-label>Search</mat-label>
    <input
      matInput
      [(ngModel)]="searchText"
      (ngModelChange)="filterList()"
      (keydown.enter)="selectOption()"
      [acsFocus]="jumpFocus === 'searchText'"
      autocomplete="off"
    />
    <button mat-icon-button matIconSuffix (click)="clearInput()" color="warn">
      <mat-icon class="fa fa-close"></mat-icon>
    </button>
  </mat-form-field>

  <span class="align-self-center col col-xs-12">
    <div class="mb-1 d-flex flex-row align-items-center justify-content-between">
      <ng-container *ngIf="setting.type === 'multiCheckSelect'">
        <mat-form-field class="mr-2 pl-0 mb-3 col-7" *ngIf="systemTypeGroup && !inModal">
          <mat-label>{{systemTypeGroup.name}}</mat-label>
          <mat-select [(ngModel)]="selectedSystemTypeGroup" (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let option of systemTypeGroup.options" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <div class="d-flex w-100" [ngClass]="showDragDropSection() ? 'justify-content-start' : (systemTypeGroup ? 'justify-content-around' : 'justify-content-center')">
        <button mat-raised-button class="md-button m-2" *ngIf="setting.showAllOption" (click)="selectAll()">All</button>
        <button mat-raised-button class="md-button m-2" *ngIf="setting.showNoneOption" (click)="unselectAll()">None</button>
        <button mat-raised-button class="md-button m-2" *ngIf="showAddButton" (click)="addOption()">Add</button>
        <ng-container *ngIf="setting.type === 'multiCheck'">
          <ng-container *ngFor="let group of setting.optionGroupings; trackBy: getIdentifierForIterableItem">
            <ng-container *ngIf="group.id !== 'systemType' || (group.id === 'systemType' && group.options.length > 1)">
              <ng-container *ngFor="let option of group.options; trackBy: getIdentifierForIterableItem">
                  <button mat-raised-button class="md-button m-2"
                          *ngIf="group.id"
                          (click)="setting.selectGroup(group.id, option.id)"> {{ option.name }}
                  </button>
              </ng-container>
              </ng-container>
          </ng-container>
        </ng-container>
      </div>

    </div>

    <div *ngIf="singleColumn" class="row mb-3">
      <div class="example-container col">
        <div class="example-list" style="max-height: 400px; overflow: auto;">
          <ng-container *ngFor="let item of setting.options; trackBy: getIdentifierForIterableItem">
            <div class="example-box" style="cursor: default;" *ngIf="item.visible || isTruckType">
              <mat-checkbox [(ngModel)]="item.selected" color="primary">
                {{ item.name || item.description }}
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </span>
</section>

<mat-list role="list" class="d-flex row" *ngIf="singleColumn ? false : !showDragDropSection()">
  <mat-list-item
    class="col-4"
    role="listitem"
    *ngFor="let option of setting.options; trackBy: getIdentifierForIterableItem">
    <mat-checkbox color="primary" [(ngModel)]="option.selected">
      <p>{{ option.name }}</p>
    </mat-checkbox>
  </mat-list-item>
</mat-list>

<div *ngIf="singleColumn ? false : showDragDropSection()" class="row mb-3" [ngStyle]="{'height': showDragDrop ? '50vh' : null}">
  <div class="example-container col">
    <h4 class="sub-header">Available</h4>

    <div
      cdkDropList
      #availableList="cdkDropList"
      [cdkDropListData]="available"
      [cdkDropListConnectedTo]="[selectedList]"
      class="example-list"
      style="max-height: 400px; overflow: auto;"
      (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of available; trackBy: getIdentifierForIterableItem">
        <div
          class="example-box"
          cdkDrag>
          <mat-checkbox
            [(ngModel)]="item.selected"
            (ngModelChange)="filterList()"
            color="primary">
            {{ item.name }}
          </mat-checkbox>
        </div>
      </ng-container>
      
    </div>
  </div>

  <div class="example-container col">
    <h4 class="sub-header">Selected</h4>

    <div
      cdkDropList
      #selectedList="cdkDropList"
      [cdkDropListData]="selected"
      [cdkDropListConnectedTo]="[availableList]"
      class="example-list"
      style="max-height: 400px; overflow: auto;"
      (cdkDropListDropped)="drop($event)">
      <div
        class="example-box"
        *ngFor="let item of selected; trackBy: getIdentifierForIterableItem"
        cdkDrag
        [cdkDragDisabled]="selected.length === 1 && !setting.allowEmpty">
        <mat-checkbox
          [(ngModel)]="item.selected"
          [disabled]="selected.length === 1 && !setting.allowEmpty"
          color="primary"
          (ngModelChange)="filterList()"
        >{{ item.name }}
      </mat-checkbox>
      </div>
    </div>
  </div>
</div>
}
@else {
  <span>No items available to choose from</span>
}


<!--     <span *ngFor="let group of setting.optionGroupings; trackBy: getIdentifierForIterableItem"> -->
<!--       <button -->
<!--         mat-raised-button -->
<!--         class="md-button m-2" -->
<!--         aria-label="" -->
<!--         *ngFor="let option of group.options; trackBy: getIdentifierForIterableItem" -->
<!--         (click)="setting.selectGroup(group.id, option.id)" -->
<!--       > -->
<!--         {{ option.name }} -->
<!--       </button> -->
<!--     </span> -->

