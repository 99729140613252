import { AggregatePit } from '@app/plants/shared/aggregate-pit.model';
import { AppConfig } from '@app/app.config';
import { BlockPlant } from '@app/plants/shared/block-plant.model';
import { ColorService } from '@app/shared/services/color.service';
import { ConcretePlant } from '@app/plants/shared/concrete-plant.model';
import { HttpService } from '@app/security/shared/http.service';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PartialPlant } from '@app/plants/shared/partial-plant.model';
import { Plant } from '@app/plants/shared/plant.model';
import { PlantForm } from '@app/plants/shared/plant-form.model';
import { SystemType } from '@app/shared/services/system-type/system-type.service';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const lgSfx = '\t(plants.service)';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private concretePlants = Array<PartialPlant>();
  private aggPlants = Array<PartialPlant>();
  private blockPlants = Array<PartialPlant>();

  private loadPlantsObserver: Observable<Array<PartialPlant>>;

  constructor(
    private colorService: ColorService,
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public saveNewPlant(plantForm: PlantForm): Observable<PlantForm> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    this.cleanCache();

    return this.http.post<PlantForm>(endpoint, plantForm, PlantForm, undefined, true);
  }

  public updatePlant(plantForm: PlantForm): Observable<PlantForm> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${plantForm.id}`;
    this.cleanCache();

    return this.http.put<PlantForm>(endpoint, plantForm, PlantForm, undefined, true);
  }

  public getPlantColorId(plantId: number): number {
    const matchingPlant = this.concretePlants
      .concat(this.aggPlants, this.blockPlants)
      .find((p) => p.id === plantId);

    if (matchingPlant) {
      return matchingPlant.colorId;
    }

    return 0;
  }

  public loadPlants(): Observable<Array<PartialPlant>> {
    const API_ENDPOINT = '/api/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    this.log.log(`getting plants from ${endpoint} ${lgSfx}`);

    this.cleanCache();

    if (this.loadPlantsObserver) {
      return this.loadPlantsObserver;
    }

    this.loadPlantsObserver = this.http.getAnyWithType(endpoint, PartialPlant).pipe(
      map((resp) => {
        const plants = this.plantsResponseHandler(resp);

        this.concretePlants = plants.filter((p) => p.systemType === SystemType.Concrete.id);
        this.aggPlants = plants.filter((p) => p.systemType === SystemType.Aggregate.id);
        this.blockPlants = plants.filter((p) => p.systemType === SystemType.Block.id);

        return plants;
      }),
      shareReplay()
    );

    return this.loadPlantsObserver;
  }

  public getPlant(id: number): Observable<Plant> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}`;

    return this.http.get<Plant>(endpoint, Plant);
  }

  public getConcretePlant(id: number): Observable<ConcretePlant> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}/concrete`;
    return this.http.get<ConcretePlant>(endpoint, ConcretePlant);
  }

  public getAggregatePit(id: number): Observable<AggregatePit> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}/agg`;

    return this.http.get<AggregatePit>(endpoint, AggregatePit);
  }

  public getBlockPlant(id: number): Observable<BlockPlant> {
    const API_ENDPOINT = '/api/v1/plants';
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${id}/block`;

    return this.http.get<BlockPlant>(endpoint, BlockPlant);
  }

  public getPlants(systemTypeId?: number, getInactive?: boolean): Observable<Array<PartialPlant>> {
    if (typeof this.loadPlantsObserver === 'undefined') {
      this.loadPlants();
    }

    return this.loadPlantsObserver.pipe(
      map(() => {
        let systemTypePlants: PartialPlant[];

        if (systemTypeId === SystemType.Concrete.id) {
          systemTypePlants = this.concretePlants;
        } else if (systemTypeId === SystemType.Aggregate.id) {
          systemTypePlants = this.aggPlants;
        } else if (systemTypeId === SystemType.Block.id) {
          systemTypePlants = this.blockPlants;
        } else {
          systemTypePlants = this.concretePlants.concat(this.aggPlants, this.blockPlants);
        }

        if (hasValue(getInactive)) {
          return systemTypePlants.filter((x) => x.inactiveFlag === getInactive);
        }

        return systemTypePlants;
      })
    );
  }

  public getPlantsForProduct(
    productId: number,
    systemTypeId: number,
    productIdList?: number[]
  ): Observable<Array<PartialPlant>> {
    const params = new URLSearchParams();
    params.set('systemTypeId', systemTypeId.toString(10));

    if (productId) {
      params.set('productId', productId.toString());
    }

    if (productIdList && productIdList.length) {
      params.set('productIdList', productIdList.toString());
    }

    const API_ENDPOINT = `/api/v1/plants/search?` + params.toString();
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http
      .getList<PartialPlant>(endpoint, PartialPlant)
      .pipe(map((resp) => this.plantsResponseHandler(resp)));
  }

  public getPlantsForProductType(productTypeId: number): Observable<Array<PartialPlant>> {
    const API_ENDPOINT = `/api/v1/plants/search-by-product-type?productTypeId=` + productTypeId;
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http
      .getList<PartialPlant>(endpoint, PartialPlant)
      .pipe(map((resp) => this.plantsResponseHandler(resp)));
  }

  public getPlantFromCache(id: number): Observable<PartialPlant> {
    const obs$ = this.loadPlantsObserver || this.loadPlants();

    return obs$.pipe(map((result) => result.find((x) => x.id === id)));
  }

  private cleanCache(): void {
    this.concretePlants = Array<PartialPlant>();
    this.aggPlants = Array<PartialPlant>();
    this.blockPlants = Array<PartialPlant>();
    this.loadPlantsObserver = undefined;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }

  private plantsResponseHandler(response: Array<PartialPlant>): Array<PartialPlant> {
    response.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    });

    return response.map((plant) => {
      const color = this.colorService.getColor(plant.colorId);
      plant.color = color ? color.hex : undefined;
      return plant;
    });
  }
}
