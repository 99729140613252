export class Material implements IMaterial {
  id: string;
  name: string;
  number: string;
  productTypeId: string;

  constructor(id?: string, name?: string, number?: string, productTypeId?: string) {
    this.id = id;
    this.name = name;
    this.number = number;
    this.productTypeId = productTypeId;
  }
}

export interface IMaterial {
  id: string;
  name: string;
  number: string;
  productTypeId: string;
}
