import { AggregatePit } from '@app/plants/shared/aggregate-pit.model';
import { BlockPlant } from '@app/plants/shared/block-plant.model';
import { ConcretePlant } from '@app/plants/shared/concrete-plant.model';
import { Plant } from '@app/plants/shared/plant.model';

export class PlantForm {
  aggregatePit: AggregatePit;
  blockPlant: BlockPlant;
  concretePlant: ConcretePlant;
  id: number;
  object: string;
  plant: Plant;
  taxScheduleId: number;

  constructor() {
    this.object = 'plantForm';
    this.plant = new Plant();
    this.aggregatePit = new AggregatePit();
    this.blockPlant = new BlockPlant();
    this.concretePlant = new ConcretePlant();
    this.taxScheduleId = 0;
  }
}
