import { TruckType } from '@app/trucks/shared/truck-type.model';
import { TruckTypeDeliveryCharge } from '@app/administration/truck-type-maintenance/models/truck-type-delivery-charge.model';
export class TruckTypeMaintenanceForm {
  id: number;
  object: string;
  truckType: TruckType;
  truckTypeDeliveryCharges: Array<TruckTypeDeliveryCharge>;

  constructor() {
    this.object = 'truckTypeMaintenanceForm';
    this.truckType = new TruckType();
    this.truckTypeDeliveryCharges = Array<TruckTypeDeliveryCharge>();
  }
}
