<div mat-dialog-title aria-label="Report Settings" class="d-flex row">
  <h2>{{ data.name }}</h2>
  <button class="ml-auto" mat-button (click)="cancel()">
    <mat-icon color="accent" aria-label="Cancel" class="fa" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content class="flex-column col">
  <div class="padding-lg">
    <div class="setting px-3" *ngFor="let settingId of data.settings.settingIds">
      <div class="row pt-2 setting-title">
        <!-- Adjust the class based on the condition -->
        <div class="col" [class.col-6]="data.settings[settingId].showOnReportOptionAvailable">
          <h3>{{ data.settings[settingId].title }}</h3>
        </div>

        <!-- Render this column only if showOnReportOptionAvailable is true -->
        <div *ngIf="data.settings[settingId].showOnReportOptionAvailable" class="col-6 text-right">
          <button *ngIf="data.settings[settingId].refresh" mat-icon-button color="primary" (click)="callSettingRefresh(settingId)">
            <mat-icon class="fa fa-rotate-right"></mat-icon>
          </button>
          <mat-slide-toggle
            *ngIf="data.settings[settingId].displayTextFunction"
            class="setting-desc-visible"
            color="primary"
            labelPosition="before"
            [(ngModel)]="data.settings[settingId].configs.visible"
            (change)="onSettingsChange()"
          >Show on report</mat-slide-toggle>
        </div>
      </div>

      <div class="row pb-3">
        <div [ngSwitch]="data.settings[settingId].type" class="col">
          <div *ngSwitchCase="'colorSelector'">
            <mat-slide-toggle class="mb-4" tabindex="-1" color="primary" (change)="setThemeColorsVisibility(data.settings[settingId])" [(ngModel)]="data.settings[settingId].value.active" aria-label="">
              {{ data.settings[settingId].displayTextFunction ? data.settings[settingId].displayTextFunction() || '' : '' }}
            </mat-slide-toggle>
            <div class="d-flex flex-sm-row justify-content-sm-start flex-column flex-sm-wrap justify-content-start" *ngIf="data.settings[settingId].id === 'themeColors' && data.settings[settingId].value.active">
              <ng-container *ngFor="let option of data.settings[settingId].value.selectedColors index as i">
                <div class="mb-2 d-flex pr-4 justify-content-between color-option-container">
                <mat-label class="pr-1 align-self-center" *ngIf="option.visible === true && option.label">{{option.label+':'}}</mat-label>
                <input *ngIf="option.visible === true" #colorOption
                       type="color"
                       [value]="option.color"
                       (change)="data.settings[settingId].value.selectedColors[i].color = colorOption.value">
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngSwitchCase="'boolean'">
            <mat-slide-toggle class="mb-4" tabindex="-1" color="primary" [(ngModel)]="data.settings[settingId].value" aria-label="">
              {{ data.settings[settingId].displayTextFunction ? data.settings[settingId].displayTextFunction() || '' : '' }}
            </mat-slide-toggle>
          </div>
          <div *ngSwitchCase="'smallText'">
            <mat-form-field>
              <mat-label *ngIf="settingId === SettingIds.interval">Minutes</mat-label>
              <input matInput [(ngModel)]="data.settings[settingId].value" name="smalltext" aria-label="" />
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'profileSelect'" class="smallTextContainer">
            <mat-form-field>
              <input matInput [(ngModel)]="data.settings[settingId].value.name"  />
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'date'">
            <mat-form-field>
              <input acsDatePickerEvtHandler matInput [matDatepicker]="picker" [(ngModel)]="data.settings[settingId].value" />
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker acsDatePickerEvtHandler #picker></mat-datepicker>
            </mat-form-field>
            <mat-slide-toggle color="primary" tabindex="-1" class="ml-2" [(ngModel)]="data.settings[settingId].configs.autoResetDaily" aria-label="Reset the date every day">
              Reset daily
            </mat-slide-toggle>
          </div>
          <div *ngSwitchCase="['multiselect', 'truckDemand'].includes(data.settings[settingId].type) ? data.settings[settingId].type : ''">
            <acs-option-multi-select [setting]="data.settings[settingId]"></acs-option-multi-select>
          </div>
          <div *ngSwitchCase="'multiCheck'" class="d-flex justify-content-between">
            <acs-option-multi-select class="d-flex flex-column align-items-center w-100 pb-2" [singleColumn]="true" [setting]="data.settings[settingId]"></acs-option-multi-select>
          </div>
          <div *ngSwitchCase="'multiCheckSelect'" class="d-flex justify-content-between">
            <acs-option-multi-select class="d-flex flex-column align-items-center w-100 pb-2" [singleColumn]="true" [setting]="data.settings[settingId]"></acs-option-multi-select>
          </div>
          <div *ngSwitchCase="'filterSelect'" class="d-flex justify-content-start py-2">
            <acs-option-filter-select [setting]="data.settings[settingId]" (visibilityChanged)="changeVisible($event)" class="d-flex flex-row align-items-start w-100 py-2"></acs-option-filter-select>
          </div>
          <div *ngSwitchCase="'autoComplete'">
            <acs-auto-complete class="mb-3" [(setting)]="data.settings[settingId]" required="data.settings[Id].required" [productTypeId]="6"></acs-auto-complete>
          </div>
          <div *ngSwitchCase="'singleSelect'" class="mat-block">
            <mat-form-field style="min-width: 200px;">
              <mat-select [disableOptionCentering]="true" aria-label="select option" [(value)]="data.settings[settingId].value" name="option">
                <mat-option *ngFor="let item of data.settings[settingId].options; trackBy: getIdentifierForIterableItem" [value]="item">
                  {{ item.name || item }}
                </mat-option>
              </mat-select>

            </mat-form-field>
          </div>
          <div *ngSwitchDefault>
            [REPORT SETTING OF TYPE {{ settingId }} NOT AVAILABLE]
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions class="justify-content-end align-items-center">
  <button color="primary" mat-raised-button [disabled]="!hasChanges()" (click)="close()">Save</button>
</div>
