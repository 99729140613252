<span class="search-options-text">Search Options: </span>
<mat-button-toggle-group class="search-toggle-group" (change)="onValChange()" [(ngModel)]="searchOption.toggleStatus" [hideSingleSelectionIndicator]="true">
    <mat-button-toggle [value]="enum.active" aria-label="Search By Active">
        <span>Active</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="enum.inactive" aria-label="Search By Inactive">
        <span>Inactive</span>
    </mat-button-toggle>
    <mat-button-toggle [value]="enum.all" aria-label="Search By All">
        <span>All</span>
    </mat-button-toggle>
</mat-button-toggle-group>
