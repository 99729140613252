import { ICheckboxMultiSelectOption } from '@app/ui/checkbox-multi-select/checkbox-multi-select.component';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkboxMultiselectPipe'
})
export class CheckboxMultiSelectPipe implements PipeTransform {
  constructor() {}

  transform(
    selectedOptions: Array<ICheckboxMultiSelectOption | number>,
    inputOptions: Array<any>
  ): any {
    if (!inputOptions?.length) {
      return 'None Available';
    }
    if (!selectedOptions?.length) {
      return '';
    }

    let msoDescriptor = '';
    let containsZed = false;
    for (const el of selectedOptions) {
      // isNaN can return false for objects!
      if (!Number.isInteger(el) && !msoDescriptor) {
        msoDescriptor = (el as ICheckboxMultiSelectOption).msoDescriptor;
      } else if (el === 0) {
        containsZed = true;
      }
    }

    const othersCount =
      selectedOptions.length -
      (selectedOptions.length > inputOptions.length || containsZed ? 2 : 1);

    return othersCount ? `${msoDescriptor} (+${othersCount} others)` : msoDescriptor;
  }
}
