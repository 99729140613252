import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';

export interface SystemColorInterface {
  id: number;
  hex: string;
  complementary: string;
  name: string;
}

export class ColorSetting {
  label: string;
  color: string;
  visible: boolean;

  constructor(hex?: string, defaultVisible?: boolean, label?: string) {
    this.color = !!hex ? hex : undefined;
    this.visible = !!defaultVisible;
    this.label = !!label ? label : undefined;
  }
}

export const WHITE_FONT_COLOR = 'white';

const colorIds: Array<SystemColorInterface> = [
  { id: 0, hex: '', complementary: '', name: 'none' },
  { id: 1, hex: '#00FF00', complementary: '#000', name: 'green' },
  { id: 2, hex: '#FFFF00', complementary: '#000', name: 'yellow' },
  { id: 3, hex: '#0000FF', complementary: '#fff', name: 'blue' },
  { id: 4, hex: '#FF00FF', complementary: '#fff', name: 'magenta' },
  { id: 5, hex: '#00FFFF', complementary: '#000', name: 'cyan' },
  { id: 6, hex: '#808080', complementary: '#fff', name: 'gray' },
  { id: 7, hex: '#964B00', complementary: '#fff', name: 'brown' },
  { id: 8, hex: '#30D5C8', complementary: '#000', name: 'turquoise' },
  { id: 9, hex: '#FF681F', complementary: '#fff', name: 'orange' },
  { id: 10, hex: '#0A480D', complementary: '#fff', name: 'dark green' },
  { id: 11, hex: '#FFC0CB', complementary: '#000', name: 'pink' },
  { id: 12, hex: '#B57EDC', complementary: '#000', name: 'lavender' },
  { id: 13, hex: '#660099', complementary: '#fff', name: 'purple' },
  { id: 14, hex: '#D2B48C', complementary: '#000', name: 'tan' },
  { id: 15, hex: '#FFFACD', complementary: '#000', name: 'light yellow' },
  { id: 16, hex: '#FFDB58', complementary: '#000', name: 'mustard' },
  { id: 17, hex: '#FF0000', complementary: '#fff', name: 'red' },
  { id: 18, hex: '#FFFFFF', complementary: '#000', name: 'white' },
  { id: 30, hex: '#0000FF', complementary: '#fff', name: 'blue' },
  { id: 31, hex: '#808080', complementary: '#fff', name: 'gray' },
  { id: 32, hex: '#00FF00', complementary: '#000', name: 'green' },
  { id: 33, hex: '#FFC0CB', complementary: '#000', name: 'pink' },
  { id: 38, hex: '#964B00', complementary: '#fff', name: 'brown' },
  { id: 39, hex: '#30D5C8', complementary: '#000', name: 'turquoise' },
  { id: 40, hex: '#FF681F', complementary: '#fff', name: 'orange' },
  { id: 41, hex: '#B57EDC', complementary: '#000', name: 'lavender' },
  { id: 42, hex: '#D2B48C', complementary: '#000', name: 'tan' },
  { id: 43, hex: '#FFFACD', complementary: '#000', name: 'light yellow' },
  { id: 3001, hex: '#23b7e5', complementary: '#fff', name: 'picton blue' },
  { id: 3002, hex: '#7266ba', complementary: '#fff', name: 'blue violet' },
  { id: 3003, hex: '#4caf50', complementary: '#fff', name: 'material green' },
  { id: 3004, hex: '#ff9800', complementary: '#fff', name: 'material orange' },
  { id: 3005, hex: '#fad733', complementary: '#fff', name: 'sun shiny yellow' },
  { id: 3006, hex: '#f44336', complementary: '#fff', name: 'material red' },
  { id: 3007, hex: '#78C5DC', complementary: '#fff', name: 'pastel cyan' },
  { id: 3008, hex: '#6392b0', complementary: '#fff', name: 'pastel blue' },
  { id: 3009, hex: '#F49AC2', complementary: '#fff', name: 'pastel magenta' },
  { id: 3010, hex: '#3f6a3c', complementary: '#fff', name: 'pastel green' },
  { id: 3011, hex: '#FDFD96', complementary: '#fff', name: 'pastel yellow' },
  { id: 3012, hex: '#FF6961', complementary: '#fff', name: 'pastel Red' }
];

@Injectable({ providedIn: 'root' })
export class ColorService {
  private readonly _colorMap: Map<number, SystemColorInterface>;

  public get colorMap(): Map<number, SystemColorInterface> {
    return this.createColorMap();
  }

  constructor(private log: Logger) {
    this._colorMap = this.createColorMap();
  }

  public getColor(colorId: number): SystemColorInterface {
    if (!colorId || colorId < 1) {
      return undefined;
    }

    const matchingColor = this._colorMap.get(colorId);

    if (!matchingColor) {
      this.log.error(`System color not found: ${colorId}`);
      return undefined;
    }

    return matchingColor;
  }

  public getColors(): Array<SystemColorInterface> {
    return colorIds;
  }

  // only used for truck-demand. Will need set max in the future
  public getDefaultColorSettingsList(limit?: number): Array<ColorSetting> {
    const colorArray = [];
    const max = limit ? limit : 8;
    colorIds.filter((color) => {
      if (color.id >= 1 && color.id <= max) {
        colorArray.push(new ColorSetting(color.hex, true));
      }
    });
    return colorArray;
  }

  public needsWhiteFont(truckFlagId: number): boolean {
    return truckFlagId === 30 || truckFlagId === 31 || truckFlagId === 38 || truckFlagId === 3002;
  }

  private createColorMap(): Map<number, SystemColorInterface> {
    const map = new Map();

    for (const color of colorIds) {
      map.set(color.id, color);
    }

    return map;
  }
}
