<ng-container *ngIf="userLoggedIn" cdkScrollable>
  <!-- NOTE: windowWidth > mediumScreenWidth doesn't prevent display on iPad air  -->
  <div *ngIf="windowWidth > mediumScreenWidth" class="short-cuts" acsHorizontalScroll>
    <acs-form-link
      *ngFor="let formId of selectedFormIds; trackBy: getIdentifierForIterableItem"
      [formId]="formId"
      [showIcon]="true"
      class="invisible-scrollbar scrollbar h-100 d-flex align-items-center"
      [useLongName]="true">
    </acs-form-link>
  </div>
  <div class="button-container">
  <div class="user-options-button">
    <button [matMenuTriggerFor]="userOptionsMenu"
            aria-label="User Options"
            class="toolbar-button last"
            color="primary"
            mat-button>
      <mat-icon fontIcon="fa-user" class="fa" aria-label="user icon"></mat-icon>
      <span *ngIf="windowWidth > smallScreenWidth" class="profile-menu-userName">{{userName}}</span>
    </button>
  </div>
  </div>

  <mat-menu #userOptionsMenu="matMenu" class="open-panel" xPosition="before">
    <ng-container *ngIf="windowWidth < smallScreenWidth">
      <span class="profile-menu-userName">{{userName}}</span>
      <mat-divider></mat-divider>
    </ng-container>
    <button mat-menu-item (click)="showSettings()">
      <mat-icon class="d-flex align-items-center fa fa-fw"><fa-icon [icon]="cogIcon" aria-label="Change report settings"></fa-icon></mat-icon>
      Settings
    </button>
    <button
      mat-menu-item
      (click)="$event.stopPropagation(); toggleTheme();">
      <mat-icon class="d-flex align-items-center fa fa-fw"><fa-icon [icon]="paletteIcon" aria-label="Change app theme"></fa-icon></mat-icon>
      Toggle Theme
    </button>
    <button mat-menu-item class="toolbar-button" *ngIf="userLoggedIn" (click)="logout()">
      <mat-icon color="warn" class="d-flex align-items-center fa fa-fw"><fa-icon [icon]="powerOffIcon" aria-label="Logout"></fa-icon></mat-icon>
      Logoff
    </button>
  </mat-menu>
</ng-container>
