export enum LookupConfigDateType {
  None,
  TimeOnly,
  DateOnly
}

export const getDateValueForConfigEnum = (
  format: LookupConfigDateType,
  value: moment.Moment
): string => {
  if (!value) {
    return '';
  }

  if (format === LookupConfigDateType.DateOnly) {
    return value.format('L');
  } else if (format === LookupConfigDateType.TimeOnly) {
    return value.format('HH:mm');
  }

  return '';
};
