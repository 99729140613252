import { Pipe, PipeTransform } from '@angular/core';
import { SearchableFiltersGeneric } from '@app/ui/autocomplete-chiplist/generic-autocomplete-chiplist.component';
import { getDateValueForConfigEnum } from '@app/ui/autocomplete-chiplist/lookup-config-date-type.enum';

@Pipe({
  name: 'chipListDescription',
  standalone: true
})
export class ChipListDescriptionPipe implements PipeTransform {
  constructor() {}

  transform(item: SearchableFiltersGeneric | any, filterObject: SearchableFiltersGeneric): any {
    if (item instanceof SearchableFiltersGeneric) {
      return item.name;
    } else {
      return (
        getDateValueForConfigEnum(filterObject.dateType, item[filterObject.objectProperty]) ||
        item[filterObject.objectProperty]
      );
    }
  }
}
