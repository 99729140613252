<div mat-dialog-title class="d-flex row">
  <h2>{{ data.title }}</h2>
  <button class="ml-auto" mat-button (click)="onNoClick()">
    <mat-icon color="accent" fontIcon="fa-times"></mat-icon>
  </button>
</div>

<div mat-dialog-content class="pb-2" style="overflow: hidden">
  <mat-card>
    <mat-card-content>
  <acs-option-multi-select [setting]="localSetting" [inModal]="true" [showDragDrop]="showDragDrop" [singleColumn]="singleColumn" (itemMovedEvent)="updateLists()"></acs-option-multi-select>
    </mat-card-content>
  </mat-card>
</div>



<div mat-dialog-actions class="px-2">
  <button
    (click)="onOkayClick()"
    [disabled]="disableOk"
    type="button"
    cdkFocusInitial
    class="md-primary ml-auto"
    color="primary"
    mat-raised-button>
    Ok
  </button>
</div>
