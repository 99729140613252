<ng-container>
  <button mat-button color="accent" aria-label="form.name" class="form-btn" (click)="openForm($event)">
  <ng-container *ngIf="showIcon">
    <mat-icon
      *ngIf="!form.faIcon; else faIconTemplate"
      class="form-link"
      [ngClass]="form.icon"
    ></mat-icon>
    <ng-template #faIconTemplate>
      <mat-icon><fa-icon class="form-link" [icon]="form.faIcon"></fa-icon></mat-icon>
    </ng-template>
  </ng-container>
    <span class="form-link-title {{specialClassForms.includes(form.enumId)  ? 'd-inline-flex align-items-center': ''}}">{{ displayName }}</span>
  </button>
</ng-container>
