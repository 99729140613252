import moment from 'moment';

export const jsonCopy = (val, replacer?, reviver?) => {
  if (replacer) {
    return; // unsupported
  }

  return JSON.parse(JSON.stringify(val), reviver);
};

export const reportDateSettingMomentReviver = (_key: string, _value: any) => {
  if (_key === 'lastValue') {
    return moment(_value);
  }

  if (_key === 'lastChangeDate') {
    return moment(_value);
  }

  if (_key === 'value') {
    return moment(_value);
  }

  return _value;
};

export const circularRefReplacer = () => {
  const seen = new WeakSet();
  return (_key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
