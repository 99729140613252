export class TruckTypeDeliveryCharge {
  chargeFactorFlag: boolean;
  chargeValue: number;
  deliveryChargeTypeId: number;
  object: string;
  truckTypeId: number;

  constructor(truckTypeId?: number, deliveryChargeTypeId?: number) {
    this.object = 'truckTypeDeliveryCharge';
    this.chargeFactorFlag = false;
    this.truckTypeId = truckTypeId;
    this.deliveryChargeTypeId = deliveryChargeTypeId;
  }
}
