import { AppConfig } from '@app/app.config';
import { Division } from '@app/divisions/shared/division.model';
import { HttpService } from '@app/security/shared/http.service';
import { IDivision } from '@app/divisions/shared/division.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable, throwError } from 'rxjs';

import { catchError, map, shareReplay } from 'rxjs/operators';

const lgSfx = '\t(divisions.service)';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {
  public divisions = Array<IDivision>();

  private userDivisionsObserver: Observable<IDivision[]>;

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public getUserDivisions(): Observable<IDivision[]> {
    if (typeof this.userDivisionsObserver === 'undefined' || this.userDivisionsObserver === null) {
      this.loadDivisions();
    }

    return this.userDivisionsObserver.pipe(
      map((divisions: Array<IDivision>) => {
        this.divisions = divisions.sort((a: IDivision, b: IDivision) => {
          if (a.abbreviation < b.abbreviation) {
            return -1;
          }

          if (a.abbreviation > b.abbreviation) {
            return 1;
          }

          return 0;
        });

        return this.divisions;
      })
    );
  }

  public getAllDivisions(): Observable<Array<IDivision>> {
    const params = new URLSearchParams();
    params.set('getAll', 'true');
    const endpoint = `${this.config.getServerUri()}/api/v1/divisions?${params.toString()}`;
    return this.http.getList<IDivision>(endpoint, Division);
  }

  public removeDivision(divisionId: number): Observable<IDivision> {
    const endpoint = `${this.config.getServerUri()}/api/v1/divisions/${divisionId}`;

    return this.http.delete(endpoint, Division, undefined, true);
  }

  public createDivision(division: Division): Observable<Division> {
    const endpoint = `${this.config.getServerUri()}/api/v1/divisions`;

    return this.http.post(endpoint, division, Division, undefined, true);
  }

  public resolve(): Observable<IDivision[]> {
    return this.loadDivisions();
  }

  private loadDivisions(): Observable<Array<IDivision>> {
    if (this.userDivisionsObserver) {
      this.log.log(`Loading divisions from cache ${lgSfx}`);
      return this.userDivisionsObserver;
    }

    this.log.log(`Loading divisions from the server ${lgSfx}`);
    const endpoint = `${this.config.getServerUri()}/api/v1/divisions?`;

    this.userDivisionsObserver = this.http.getList<IDivision>(endpoint, Division).pipe(
      map((divisions) => {
        this.divisions = divisions;
        return this.divisions;
      }),
      shareReplay(),
      catchError((err) => {
        this.log.error(`Error loading divisions from API ${lgSfx}`, err);
        return throwError('Error loading divisions from API.');
      })
    );

    return this.userDivisionsObserver;
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.divisions = Array<IDivision>();
    this.userDivisionsObserver = undefined;
  }
}
