import { Moment } from 'moment';
import { FormulaProduct } from '@app/products/models/formula-product.model';
import { UnitOfMeasure } from '@app/products/models/unit-of-measure.model';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';
import { validateNotNullFields } from '@app/shared/forms/validation-helpers.utility';

export class Product {
  id: number; // int NOT NULL,
  classId: string; // varchar(15) NULL,
  description: string; // varchar(100) NULL,
  discountAmount: number; // numeric(18, 2) NULL,
  discountableFlag: boolean; // bit NOT NULL,
  formula: FormulaProduct;
  glCategoryId: number; // int NULL,
  importSetupKey: number; // int NULL CONSTRAINT DF__Product__Import___6205949C  DEFAULT ((1)),
  inactiveFlag: boolean; // bit NOT NULL,
  loadSize: number;
  marginIndex: number; // numeric(18, 4) NOT NULL,
  number: string; // varchar(100) NOT NULL,
  price: number; // numeric(18, 4) NULL,
  productSubtypeId: number; // int NULL,
  productTypeId: number; // int NOT NULL,
  plantId: number; // int NULL
  pstTaxExemptFlag: boolean; // bit NOT NULL CONSTRAINT DF__Product__PST_Tax__3533B97C  DEFAULT ((0)),
  quantity: number;
  quotedProductOnly: boolean; // bit NOT NULL CONSTRAINT DF__Product__Quoted___7094766C  DEFAULT ((0))
  slump: number;
  taxableFlag: boolean; // bit NOT NULL,
  trackInventoryFlag: boolean; // bit NOT NULL,
  unitOfMeasure: UnitOfMeasure;
  unitOfMeasureId: number; // int NOT NULL,
  usageType: number;

  // quote fields
  quoteUsageTypeId: number;
  quoteDescription: string;

  get productDescription(): string {
    return this.quoteDescription || this.description;
  }

  private _modifiedDateTime: Moment; // datetime NOT NULL,
  get modifiedDateTime(): Moment {
    return this._modifiedDateTime;
  }
  set modifiedDateTime(val) {
    this._modifiedDateTime = convertIso8601ToDate(val);
  }

  get productId(): number {
    return this.id;
  }

  constructor() {
    this.usageType = 0;
  }

  validate(): string[] {
    const validateArray = ['description', 'productTypeId', 'unitOfMeasureId', 'number'];

    if (this.productTypeId === 1) {
      validateArray.push('formula');
    }

    if (this.productTypeId !== 6) {
      validateArray.push('price');
    }

    return validateNotNullFields(this, validateArray, 'Product');
  }
}
