import moment from 'moment';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { AuthorizationService } from '@app/security/shared/authorization.service';
import { ColorService, ColorSetting } from '@app/shared/services/color.service';
import { ConfigProfile } from '@app/truck-tracking/config-profile.model';
import { DivisionService } from '@app/divisions/shared/divisions.service';
import { Form } from '@app/forms/form.model';
import { GroupOption } from '@app/reports/report-settings/group-option.model';
import { Grouping } from '@app/reports/report-settings/grouping.model';
import { IDivision } from '@app/divisions/shared/division.interface';
import { IGrouping } from '@app/reports/report-settings/grouping.interface';
import { IMaterial } from '@app/reports/material-demand/material.model';
import { Injectable, Optional } from '@angular/core';
import {
  ILegacyMultiSelectAppSetting,
  IMultiSelectAppSetting
} from '@app/reports/report-settings/report-settings.interface';
import {
  IReportSetting,
  PlantSettingType,
  SettingType
} from '@app/reports/report-settings/report-setting.interface';
import { IReportSettingsManager } from '@app/reports/report-settings/report-settings-manager.interface';
import { ITruckType } from '@app/trucks/shared/truck-type.interface';
import { LoadSchedulerUiConfig } from '@app/truck-tracking/ui-config/load-scheduler-ui-config';
import { Logger } from '@app/logger/logger';
import { MaterialDemandReportService } from '@app/reports/material-demand/material-demand-report.service';
import { Moment } from 'moment';
import { PartialPlant } from '@app/plants/shared/partial-plant.model';
import { PlantService } from '@app/plants/shared/plant.service';
import { QuickFormOption } from '@app/ui/toolbar/models/quick-form-option.model';
import { ReportSettingsManager } from '@app/reports/report-settings/report-settings-manager.model';
import { ReportTypeService } from '@app/reports/report-types/report-types.service';
import { Setting } from '@app/reports/report-settings/setting.model';
import { TruckTypeService } from '@app/trucks/shared/truck-types.service';
import {
  SYSTEM_TYPE_ARRAY,
  SystemType,
  SystemTypeService
} from '@app/shared/services/system-type/system-type.service';
import { TranslateWrapperService } from '@app/shared/services/translate-wrapper.service';
import { first, map, shareReplay } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';
import { jsonCopy, reportDateSettingMomentReviver } from '@app/shared/utilities/json-copy.utility';
import {
  INTERVAL_SETTING_OPTIONS,
  truckGraphTypes,
  xAxisOptions
} from '@app/reports/truck-demand/truck-demand-graph-options';
import { SystemParameterService } from '@app/system-parameters/shared/system-parameters.service';

const invalidSettingIds = ['addSetting', 'count', 'merge', 'removeSetting', 'settingIds'];

export enum SettingIds {
  boolean = 'boolean',
  date = 'date',
  material = 'material',
  smallText = 'smallText',
  reportTypeSelect = 'reportTypeSelect',
  graphType = 'graphType',
  interval = 'interval',
  xAxisLabel = 'xAxisLabel',
  profiles = 'profiles',
  gridConfiguration = 'gridConfiguration',
  plants = 'plants',
  truckTypes = 'truckTypes',
  language = 'language',
  themeColors = 'themeColors'
}
// todo remove from global space
// todo look into removing toolbar/quickform opts functionality?
/**
 * This service acts as a factory for creating objects of type IReportSetting.
 * It also provides functionality to mimic a deep copy of a collection of
 * ReportSetting objects stored in a ReportSettingsManager object.
 *
 * @notes
 * - If using multiple date or boolean type settings for a single ReportSettingsManager
 *   then unique values for 'id' will need to manually be set at creation time.
 */
@Injectable({ providedIn: 'root' })
export class ReportSettingsService {
  constructor(
    private authorizationService: AuthorizationService,
    private colorService: ColorService,
    private divisionService: DivisionService,
    private log: Logger,
    private plantService: PlantService,
    private reportTypeService: ReportTypeService,
    private systemParameterService: SystemParameterService,
    private systemTypeService: SystemTypeService,
    private truckTypeService: TruckTypeService,
    @Optional() private materialReportService: MaterialDemandReportService
  ) {}

  public getBooleanSetting(
    settingTitle: string,
    settingId: string,
    displayText?: string,
    defaultValue = false
  ): IReportSetting {
    const settingParams = {
      title: settingTitle,
      id: settingId,
      type: 'boolean',
      value: defaultValue,
      options: undefined
    };

    const setting = this.createSetting(settingParams);

    if (displayText) {
      setting.displayTextFunction = () => displayText;
    }

    setting.getAppSettingValue = () => setting.value === true;

    setting.loadAppSettingValue = (val: boolean) => {
      if (typeof val !== 'undefined' && val !== null) {
        setting.value = val === true;
      }
    };

    return setting;
  }

  /**
   * Note: Dates are saved as utc. When retreiving to use for API requests
   * or to display on screen they should be converted to local using .local().
   */
  public getDateSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Date',
      id: 'date',
      type: 'date',
      value: moment.utc(),
      options: undefined,
      configs: {
        autoResetDaily: true,
        lastChangeDate: moment.utc(),
        lastValue: moment.utc(),
        visible: false
      },
      showOnReportOptionAvailable: true
    };
    const setting = this.createSetting(settingParams);

    setting.displayTextFunction = () =>
      setting.value ? moment(setting.value).local().format('L') : '';

    setting.getAppSettingValue = () => {
      if (!this.dayValuesAreEqual(setting.configs.lastValue, moment(setting.value))) {
        setting.configs.lastChangeDate = moment.utc();
        setting.configs.lastValue = setting.value;
      }
      return {
        configs: {
          autoResetDaily: setting.configs.autoResetDaily,
          lastChangeDate: setting.configs.lastChangeDate,
          lastValue: setting.configs.lastValue,
          visible: setting.configs.visible
        },
        value: setting.value
      };
    };

    setting.loadAppSettingValue = (val: any) => {
      if (typeof val !== 'undefined' && val !== null) {
        setting.configs.autoResetDaily =
          val && val.configs && val.configs.autoResetDaily !== undefined
            ? val.configs.autoResetDaily
            : setting.configs.autoResetDaily;
        setting.configs.lastChangeDate = moment.utc(
          val && val.configs && val.configs.lastChangeDate !== undefined
            ? val.configs.lastChangeDate
            : setting.configs.lastChangeDate
        );
        setting.configs.lastValue = moment.utc(
          val && val.configs && val.configs.lastValue !== undefined
            ? val.configs.lastValue
            : setting.configs.lastValue
        );
        setting.configs.visible = hasValue(val.configs?.visible)
          ? val.configs.visible
          : setting.configs.visible;

        setting.value = moment.utc(val.value);
        this.onLoad(setting);
      }
    };

    return setting;
  }

  public getNameSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Title',
      id: 'smallText',
      type: 'smallText',
      value: '',
      options: undefined
    };
    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value;

    setting.loadAppSettingValue = (val: any) => {
      if (val) {
        setting.value = val;
      }
    };

    return setting;
  }

  public getReportTypeSetting(): IReportSetting {
    const settingParams = {
      title: 'Report Type',
      id: 'reportTypeSelect',
      type: 'singleSelect',
      value: undefined,
      options: [],

      whenReady: () => of('')
    };
    const setting = this.createSetting(settingParams);

    this.authorizationService.formPermissionsLoadedObserver
      .pipe(first((isPermissionLoaded) => isPermissionLoaded === true))
      .subscribe(() => {
        this.loadWhenPermissionsReady(setting);
      });

    setting.getAppSettingValue = () => setting.value.id;

    setting.loadAppSettingValue = (val: string) => {
      this.authorizationService.formPermissionsLoadedObserver
        .pipe(first((isPermissionLoaded) => isPermissionLoaded === true))
        .subscribe(() => {
          if (typeof val !== 'undefined' && val !== null) {
            setting.value = setting.options.find((option) => option.id === val);
          }
        });
    };
    return setting;
  }

  public getGraphTypeSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Graph Type',
      id: 'graphType',
      type: 'singleSelect',
      value: undefined,
      options: truckGraphTypes
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value?.id;

    setting.loadAppSettingValue = (val: any) => {
      if (hasValue(val)) {
        setting.value = setting.options.find((option) => option.id === val);
      }
    };

    return setting;
  }

  public getXAxisLabelSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'X-Axis Label',
      id: 'xAxisLabel',
      type: 'singleSelect',
      value: undefined,
      options: xAxisOptions
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value?.id;

    setting.loadAppSettingValue = (val: any) => {
      if (hasValue(val)) {
        setting.value = setting.options.find((option) => option.id === val);
      }
    };

    return setting;
  }

  public getNewReportSettings(): IReportSettingsManager {
    return new ReportSettingsManager();
  }

  public getProfileSetting(): IReportSetting {
    const settingParams = {
      title: 'Profile',
      id: 'profiles',
      type: 'profileSelect',
      value: undefined,
      showSystemType: true,
      options: [],
      filter: [],
      optionGroupings: []
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value;

    setting.loadAppSettingValue = (profile: ConfigProfile) => {
      setting.value = Object.assign(new ConfigProfile(), profile);
    };

    return setting;
  }

  public getUILayoutSetting(): IReportSetting {
    const settingParams = {
      title: 'Grid Configuration',
      id: 'gridConfiguration',
      type: 'multiselect',
      value: undefined,
      showSystemType: true,
      options: [],
      filter: [],
      optionGroupings: []
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => {
      if (setting.options.length) {
        return setting.options;
      } else {
        return LoadSchedulerUiConfig;
      }
    };

    setting.loadAppSettingValue = (lssUiConfigs: any[]) => {
      setting.options = [].concat(...lssUiConfigs);
      setting.options.forEach((opt) => {
        opt.selected = !opt.hide;
      });
    };

    return setting;
  }

  public getPlantsSetting(
    override?: PlantSettingType | IReportSetting,
    truckTracking?: boolean,
    allowedSystemTypes?: number[]
  ): IReportSetting<PartialPlant> {
    const groupSystemTypes = new Grouping('systemType', 'System Types');
    const groupDivisions = new Grouping('divisionId', 'Divisions');
    let params: IReportSetting;
    let type: SettingType;

    if (typeof override === 'string') {
      type = override ? override : 'multiselect';
    } else {
      params = override;
      type = override?.type ?? 'multiselect';
    }

    const settingParams = params ?? {
      title: 'Plants',
      id: 'plants',
      type,
      value: undefined,
      showSystemType: true,
      options: [],
      filter: [],
      configs: {
        visible: false,
        systemTypeId: undefined
      },
      allowEmpty: true,
      optionGroupings: [groupSystemTypes, groupDivisions],
      showOnReportOptionAvailable: true,
      allowedSystemTypes: []
    };

    const setting = this.createSetting(settingParams) as IReportSetting<PartialPlant>;

    setting.displayTextFunction = () =>
      setting.options
        .filter((plant: PartialPlant) => {
          if (setting.type === 'truckDemand') {
            return plant.selectionConfirmed;
          }

          if (plant.selected && plant.visible) {
            return true;
          }
        })
        .map((plant) => plant.name)
        .join(', ');

    setting.whenReady = () => {
      setting.initialized = true;
      return setting.dataObservable;
    };

    // Filter object has the properties `id` and `data`
    //
    // Supported filters:
    //   systemType: array(int)
    setting.applyFilters = () => {
      setting.filter.map((filter: any) => {
        switch (filter.type) {
          case 'systemType':
            setting.options
              .filter(
                (plant: any) =>
                  // Return if plant system type is NOT in the filter
                  filter.data.indexOf(plant.systemType) === -1
              )
              .map((plant: any) => {
                // Set the plants visibility to hidden
                plant.visible = false;
                plant.selected = false;
              });
            break;
        }
      });
    };

    setting.dataObservable = forkJoin([
      this.systemTypeService.waitUserSystemTypesLoaded$,
      this.divisionService.getUserDivisions(),
      this.plantService.getPlants(undefined, false)
    ]).pipe(
      map(([_systemTypeLoaded, divisions, plants]) => {
        for (const plant of plants) {
          if (this.systemTypeService.userSystemTypes.has(plant.systemType)) {
            plant.visible = true;
            plant.selected = false;

            // just to hardcode concrete for truck demand as it is only one that uses 'filterSelect'
            // if we add addtl system types in the future update filter select to use system type dropdown
            // similar to the LSS
            // note (2024-07-25): updated to using truckDemand since so far it is a unique case
            // filterSelect is no longer used but leaving it as a 'backup'
            // ? could we just filter using the current systemType parameter instead?
            if (setting.type === 'filterSelect' || setting.type === 'truckDemand') {
              if (plant.systemType === SystemType.Concrete.id) {
                setting.options.push(Object.assign(new PartialPlant(), plant));
              }
            } else {
              setting.options.push(Object.assign(new PartialPlant(), plant));
            }
          }
        }

        // set a default value based on the users system types for new settings
        const [firstSystemType] = this.systemTypeService.userSystemTypes;
        const activePlants = setting.options.filter(
          (x: PartialPlant) => x.systemType === firstSystemType && !x.inactiveFlag
        );
        activePlants.reduce((prev, current) =>
          prev.id < current.id ? prev : current
        ).selected = true;

        setting.applyFilters();

        this.updateDivisionGrouping(setting, groupDivisions, divisions);
        this.updateSystemGrouping(groupSystemTypes, truckTracking, allowedSystemTypes);

        return setting.options.filter((x) => x.selected);
      }),
      shareReplay() // ? can we remove
    );

    setting.getAppSettingValue = (): IMultiSelectAppSetting => {
      // get system type based on existing config or default to first available in optionGroupings
      const configuredSystemTypeId =
        setting.configs.systemTypeId ||
        setting.optionGroupings.find((x) => x.id === 'systemType').options[0]?.id;
      // set visible plants based on the setting type and the default system type id filter
      const visiblePlants = [];
      const tdSelectedPlants = [];
      setting.options.forEach((plant) => {
        if (setting.type === 'multiCheckSelect') {
          if (plant.systemType === configuredSystemTypeId) {
            plant.visible = true;
            visiblePlants.push(plant);
          } else {
            plant.visible = false;
          }

          return;
        }

        if (plant.visible) {
          visiblePlants.push(plant);
        }

        if (!plant.selected) {
          plant.selectionConfirmed = false;
        }

        if (plant.selectionConfirmed) {
          tdSelectedPlants.push(plant);
        }
      });

      return {
        configs: {
          visible: setting.configs.visible,
          systemTypeId: configuredSystemTypeId
        },
        value: {
          selected: setting.options
            .filter((plant: any) => plant.selected)
            .map((plant: PartialPlant) => plant.id),
          visible: visiblePlants.map((plant: PartialPlant) => plant.id),
          selectionConfirmed: tdSelectedPlants.map((plant) => plant.id)
        }
      };
    };

    setting.loadAppSettingValue = (val: IMultiSelectAppSetting | ILegacyMultiSelectAppSetting) => {
      const defaultSystemType = setting.optionGroupings.find((x) => x.id === 'systemType')
        .options[0]?.id;

      // item is IMultiSelectAppSetting
      if (!Array.isArray(val.value)) {
        const typedLoadingVal = val as IMultiSelectAppSetting;
        // handle setting from new object
        if (val.value.selected?.length) {
          setting.options.forEach((plant) => {
            plant.selected = !!typedLoadingVal.value.selected.includes(plant.id);
          });
        }

        if (val.value.selectionConfirmed?.length) {
          setting.options.forEach((plant) => {
            plant.selectionConfirmed = !!typedLoadingVal.value.selectionConfirmed.includes(
              plant.id
            );
          });
        }

        // for saved settings that are being restored
        if (typedLoadingVal.value.visible?.length) {
          setting.options.forEach((plant) => {
            // handle setting all plants visible based on selected systemType used for LSS
            // if multi select?
            if (
              typedLoadingVal.configs.systemTypeId &&
              setting.type !== 'multiCheck' &&
              setting.type !== 'filterSelect'
            ) {
              plant.visible = plant.systemType === typedLoadingVal.configs.systemTypeId;
            } else {
              // handle setting visible for the dashboard Truck-demand where config menu will limit visible options
              plant.visible = !!typedLoadingVal.value.visible.includes(plant.id);

              if (!typedLoadingVal.configs.systemTypeId) {
                setting.configs.systemTypeId = defaultSystemType;
              }
            }
          });
        }
      } else {
        const typedLoadingVal = val as ILegacyMultiSelectAppSetting;
        // for no settings saved/outdated saved settings using value and not visible/selected, load the defaults
        if (typedLoadingVal.value && typedLoadingVal.value.length > 0) {
          if (!typedLoadingVal.configs.systemTypeId) {
            typedLoadingVal.configs.systemTypeId = defaultSystemType;
          }
          setting.options.forEach((plant) => {
            const isIncluded = typedLoadingVal.value.includes(plant.id);
            const isSameSystemType = plant.systemType === typedLoadingVal.configs.systemTypeId;

            plant.selected = isIncluded && isSameSystemType;
            plant.visible = isSameSystemType;
          });
        } else {
          setting.options.map((plant: any) => {
            plant.selected = false;
            // for defaulting plant selections to be visible preventing an initial empty available array for LSS
            if (setting.configs.systemTypeId) {
              plant.visible = plant.systemType === setting.configs.systemTypeId;
            } else {
              // default for truck-demand etc.
              plant.visible = true;
            }
          });
          if (!setting.configs.systemTypeId) {
            setting.configs.systemTypeId = defaultSystemType;
          }
        }
      }

      // prevents a single selected plant from disabling the drag drop and checkbox. Plants in the selection boxes
      // can at times have none selected, component logic should handle disable/limit the min selected
      setting.allowEmpty = true;

      setting.configs.visible = hasValue(val.configs?.visible)
        ? val.configs.visible
        : setting.configs.visible;

      setting.configs.systemTypeId = hasValue(val.configs?.systemTypeId)
        ? val.configs.systemTypeId
        : setting.configs.systemTypeId;
    };

    return setting;
  }

  public getQuickFormsSetting(): IReportSetting {
    const settingParams = {
      title: 'Quick Forms',
      id: 'forms',
      type: 'multiselect',
      value: undefined,
      options: [],
      filter: [],
      initialized: false,
      allowEmpty: true
    };

    const setting = this.createSetting(settingParams);
    setting.showAllOption = false;

    const readyObserver = forkJoin([
      this.authorizationService.waitFormPermissionsLoaded$,
      this.systemTypeService.waitUserSystemTypesLoaded$
    ]).pipe(
      map(() => {
        setting.initialized = true;
        this.setupQuickFormOptions(setting);
      })
    );

    setting.whenReady = () => readyObserver;

    setting.getAppSettingValue = () =>
      setting.options
        .filter((option: any) => option.selected && option.visible)
        .map((option: any) => option.id);

    setting.loadAppSettingValue = (selectedFormIds: Array<string>) => {
      if (setting.initialized) {
        if (Array.isArray(selectedFormIds) === false) {
          return;
        }

        setting.options.forEach((option: any) => {
          option.selected = option.visible && selectedFormIds.indexOf(option.id) > -1;
        });
      } else {
        readyObserver.subscribe(() => {
          setting.loadAppSettingValue(selectedFormIds);
        });
      }
    };

    return setting;
  }

  public getThemeSetting(maxColors?: number, params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Color',
      id: 'themeColors',
      type: 'colorSelector',
      value: { selectedColors: [], active: false },
      options: undefined,
      configs: {}
    };

    settingParams.configs['maxColors'] = maxColors;

    const setting = this.createSetting(settingParams);

    setting.displayTextFunction = () => 'Use Theme Colors';

    setting.getAppSettingValue = () => setting.value;

    setting.loadAppSettingValue = (loadedSettings: {
      selectedColors: Array<ColorSetting>;
      active: boolean;
    }) => {
      setting.value.active = !!loadedSettings?.active;
      setting.value.selectedColors = loadedSettings?.selectedColors?.length
        ? loadedSettings.selectedColors
        : this.colorService.getDefaultColorSettingsList(maxColors);
    };

    return setting;
  }

  public getTruckTypesSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Truck Types',
      id: 'truckTypes',
      type: 'multiCheck',
      value: undefined,
      options: []
    };

    const setting = this.createSetting(settingParams);

    setting.dataObservable = this.truckTypeService.resolve().pipe(
      map((truckTypes: Array<ITruckType>) => {
        const filteredTruckTypes = truckTypes.filter(
          (truckType: ITruckType) => truckType.systemTypeId === SystemType.Concrete.id
        );
        setting.options = [...filteredTruckTypes];
        // by default set all as selected for new records. Loaded records will over-write this.
        setting.options.forEach((truckType: ITruckType) => (truckType.selected = true));
      })
    );

    setting.getAppSettingValue = (): {
      value: { selected: Array<number> };
    } => ({
      value: {
        selected: setting.options
          .filter((truckType: ITruckType) => truckType.selected)
          .map((truckType: ITruckType) => truckType.id)
      }
    });

    setting.loadAppSettingValue = (loadedSetting?: IMultiSelectAppSetting) => {
      if (loadedSetting?.value?.selected?.length) {
        setting.options = setting.options.map((truckType: ITruckType) => ({
          ...truckType,
          selected:
            !!loadedSetting.value.selected.includes(truckType.id) &&
            truckType.systemTypeId === SystemType.Concrete.id
        }));
      } else {
        setting.options.forEach((truckType) => (truckType.selected = true));
      }
    };

    setting.whenReady = () => {
      setting.initialized = true;
      return setting.dataObservable;
    };

    return setting;
  }

  public getLanguageSetting(): IReportSetting {
    const settingParams = {
      title: 'Language',
      id: 'language',
      type: 'singleSelect',
      value: undefined,
      options: TranslateWrapperService.languages
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value;

    setting.loadAppSettingValue = (val: any) => {
      if (hasValue(val)) {
        setting.value = setting.options.find((option) => option === val);
      }
    };

    return setting;
  }

  public getIntervalSetting(params?: IReportSetting): IReportSetting {
    const settingParams = params ?? {
      title: 'Interval',
      id: SettingIds.interval,
      type: 'singleSelect',
      value: undefined,
      options: INTERVAL_SETTING_OPTIONS
    };

    const setting = this.createSetting(settingParams);

    setting.getAppSettingValue = () => setting.value?.id;

    setting.loadAppSettingValue = (val: any) => {
      if (hasValue(val)) {
        setting.value = setting.options.find((option) => option.id === val);
      }
    };

    return setting;
  }

  public getMaterialsSetting(
    associatedPlantSetting: IReportSetting,
    params?: IReportSetting
  ): IReportSetting {
    const settingParams = params ?? {
      title: 'Raw Material',
      id: 'material',
      type: 'autoComplete',
      value: undefined,
      showSystemType: true,
      options: [],
      optionIdProperty: 'id',
      filter: [],
      required: true,
      configs: {
        visible: false,
        displayText: undefined
      },
      displayTextFunction(): string {
        return this.value?.name;
      }
    };

    const setting = this.createSetting(settingParams);

    setting.dependentSettings = [associatedPlantSetting];

    setting.whenReady = () => {
      setting.initialized = true;
      setting.refresh();
      return setting.dataObservable;
    };

    if (this.materialReportService) {
      setting.refresh = (subscribe?: boolean) => {
        const plantIds = associatedPlantSetting.options.filter((x) => x.selected).map((x) => x.id);
        setting.dataObservable = this.materialReportService.getMaterials(plantIds).pipe(
          map(
            (materials: Array<IMaterial>) => {
              setting.options.splice(0, setting.options.length);
              Array.prototype.push.apply(setting.options, materials);

              if (
                typeof setting.value === 'undefined' ||
                (setting.value === null && setting.options.length > 0)
              ) {
                setting.value = setting.options[0];
              }

              return materials;
            },
            (err) => throwError('Error loading Materials from API.' + err)
          )
        );

        if (subscribe) {
          setting.dataObservable.subscribe();
        }
      };
    }

    setting.getAppSettingValue = () => {
      if (typeof setting.value !== 'undefined' && setting.value !== null) {
        return {
          configs: {
            visible: setting.configs.visible,
            displayText: setting.configs.displayText
          },
          value: setting.value.id
        };
      } else {
        return setting.options;
      }
    };

    setting.loadAppSettingValue = (materialData: any) => {
      setting.refresh();
      return setting.dataObservable.pipe(
        map((_) => {
          if (typeof materialData !== 'undefined' && materialData !== null) {
            setting.options.map((material: IMaterial) => {
              if (materialData.value === material.id) {
                setting.value = material;
                setting.configs.displayText = material.name;
              }
            });
            setting.configs.visible =
              materialData && materialData.configs && materialData.configs.visible !== undefined
                ? materialData.configs.visible
                : setting.configs.visible;
          }
        })
      );
    };
    return setting;
  }

  public createSetting(settingParams: any): Setting {
    if (
      invalidSettingIds.includes(settingParams.id) === true ||
      settingParams.id.toString().length === 0
    ) {
      this.log.error('Invalid setting ID: ' + settingParams.id);
    }

    return new Setting(settingParams);
  }

  /**
   * Provides functionality to mimic a deep copy of a ReportSettingsManager.
   * Primarily used when we need to edit settings but we don't want to mutate
   * the actual settings object.
   *
   * It is important that this does not mutate the original/passed in object!
   */
  public deepCopySetting(settingsToCopy: ReportSettingsManager): ReportSettingsManager {
    const clone = new ReportSettingsManager();
    clone.settingIds = settingsToCopy.settingIds;
    for (const settingId of clone.settingIds) {
      const settingToCopy = settingsToCopy[settingId] as Setting;
      switch (settingId) {
        case SettingIds.graphType:
          clone[settingId] = this.getGraphTypeSetting(settingToCopy);
          break;
        case SettingIds.interval:
          clone[settingId] = this.getIntervalSetting(settingToCopy);
          break;
        case SettingIds.plants:
          clone[settingId] = this.getPlantsSetting(jsonCopy(settingToCopy));
          break;
        case SettingIds.truckTypes:
          clone[settingId] = this.getTruckTypesSetting(settingToCopy);
          break;
        case SettingIds.themeColors:
          clone[settingId] = this.getThemeSetting(
            settingToCopy.configs.maxColors,
            jsonCopy(settingToCopy)
          );
          break;
        case SettingIds.xAxisLabel:
          clone[settingId] = this.getXAxisLabelSetting(settingToCopy);
          break;
        case SettingIds.smallText:
          clone[settingId] = this.getNameSetting(jsonCopy(settingToCopy));
          break;
        case SettingIds.material:
          break;
        default:
          if (settingToCopy.type === 'boolean') {
            clone[settingId] = this.getBooleanSetting(
              settingToCopy.title,
              settingToCopy.id,
              settingToCopy.displayTextFunction?.(),
              settingToCopy.value
            );
            break;
          }

          if (settingToCopy.type === 'date') {
            clone[settingId] = this.getDateSetting(
              jsonCopy(settingToCopy, undefined, reportDateSettingMomentReviver)
            );
            break;
          }

          this.log.error(
            `Unhandled report setting type ${settingId}. Creating default`,
            settingToCopy
          );
          clone[settingId] = new Setting(settingsToCopy[settingId]);
          break;
      }
    }

    // if material exists we want to create this setting after plants
    if (clone.settingIds.includes(SettingIds.material)) {
      clone[SettingIds.material] = this.getMaterialsSetting(
        clone[SettingIds.plants],
        settingsToCopy[SettingIds.material]
      );
    }

    return clone;
  }

  private updateDivisionGrouping(
    setting: IReportSetting,
    groupDivisions: IGrouping,
    userDivisions: IDivision[]
  ): void {
    // get division ids on available plants from setting options
    const availablePlantDivisions = Array.from(
      new Set(
        setting.options.filter((option) => option.visible === true).map((p: any) => p.divisionId)
      )
    );

    groupDivisions.clearOptions();

    if (availablePlantDivisions.length > 1 && userDivisions.length > 0) {
      availablePlantDivisions.forEach((divisionId: number) => {
        const matchedDivision = userDivisions.find(
          (division: IDivision) => division.id === divisionId
        );

        if (matchedDivision) {
          groupDivisions.addOption(
            new GroupOption(matchedDivision.id, matchedDivision.abbreviation)
          );
        } else {
          this.log.warn(`Plant had division not found in user divisions`);
        }
      });
    }
  }

  private updateSystemGrouping(
    groupSystemTypes: IGrouping,
    truckTracking?: boolean,
    allowedSystemTypes?: number[]
  ): void {
    groupSystemTypes.clearOptions();
    this.systemTypeService.userSystemTypes.forEach((systemTypeId: number) => {
      if (allowedSystemTypes?.length && !allowedSystemTypes.includes(systemTypeId)) {
        return;
      }
      const systemTypeName = SYSTEM_TYPE_ARRAY.find((x) => x.id === systemTypeId).name;
      groupSystemTypes.addOption(new GroupOption(systemTypeId, systemTypeName));
    });

    // filter systemtypes by allowed truck tracking system parameters. Used in truck-tracking
    if (truckTracking) {
      const allowedTrackingTypes = this.systemParameterService.getEnabledTruckTrackingSystemTypes();
      groupSystemTypes.options = groupSystemTypes.options.filter((x: GroupOption) =>
        allowedTrackingTypes.includes(x.id)
      );
    }
  }

  private onLoad(setting: IReportSetting): void {
    if (
      setting.configs.autoResetDaily === true &&
      !this.dayValuesAreEqual(moment.utc(), setting.configs.lastChangeDate)
    ) {
      setting.value = moment.utc();
      setting.configs.lastChangeDate = moment.utc();
      setting.configs.lastValue = setting.value;
    }
  }

  private dayValuesAreEqual(firstDay: Moment, secondDay: Moment): boolean {
    const dateCompFormat = 'YYYYMMDD';
    return firstDay.local().format(dateCompFormat) === secondDay.local().format(dateCompFormat);
  }

  private setupQuickFormOptions(setting: IReportSetting): void {
    setting.options.splice(0, setting.options.length);

    // Note that two pairs turns the constant into an array of pairs such that
    // the first element is the text of the object property and the second element
    // is the value.
    const forms: [string, Form][] = Object.keys(AUJS_FORMS).map((data) => [data, AUJS_FORMS[data]]);

    const availableForms = forms.filter(
      (form: [string, Form]) => form[1].enabled && form[1].availableInQuickMenu
    );

    availableForms.forEach((form: [string, Form]) => {
      const formPermission = this.authorizationService.getFormPermission(form[1].id);
      const validSystemType = form[1].systemTypeId
        ? this.systemTypeService.userSystemTypes.has(form[1].systemTypeId)
        : true;
      if ((validSystemType && formPermission.userCanView()) || form[1].alwaysShow) {
        const newOption = new QuickFormOption();
        newOption.id = form[0];
        newOption.name = form[1].longName;
        newOption.visible = true;
        newOption.selected = false;
        newOption.legacy = form[1].legacy;
        setting.options.push(newOption);
      }
    });

    setting.options = setting.options.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }

      return 0;
    });
  }

  private loadWhenPermissionsReady(setting: IReportSetting): void {
    setting.options = this.reportTypeService.getReportTypesForUser();
  }
}
