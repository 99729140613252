import { IGrouping } from '@app/reports/report-settings/grouping.interface';

export class Grouping implements IGrouping {
  id: GroupingId;
  name: string;
  options: Array<any>;

  constructor(id?: GroupingId, name?: string) {
    this.id = id;
    this.name = name;
    this.options = [];
  }

  public addOption(option: any): void {
    const indexOfGrouping = this.options.findIndex(
      (grouping: IGrouping) => grouping.id === option.id
    );

    if (indexOfGrouping > -1) {
      this.options[indexOfGrouping] = option;
    } else {
      this.options.push(option);
    }
  }

  public clearOptions(): void {
    this.options.splice(0, this.options.length);
  }
}

type GroupingId = 'systemType' | 'divisionId';
