import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { Product } from '@app/products/models/product.model';
import { ProductSearchableFilter } from '@app/ui/autocomplete-chiplist/products/searchable-filter.model';
import { ProductTypeIds } from '@app/products/models/product-type.model';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'acs-product-autocomplete-chiplist',
  styles: [
    '.mat-mdc-form-field {margin: 8px 8px 0 8px ; font-size: 14px;}',
    '.mat-mdc-chip {background-color:#cceef9 }',
    '.mat-mdc-chip:hover {background-color:#9adef3 }'
  ],
  templateUrl: './product-autocomplete-chiplist.component.html'
})
export class ProductAutocompleteChiplistComponent implements DoCheck {
  @ViewChild('filterInput', { static: false })
  filterInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autoComplete: MatAutocompleteTrigger;
  @Input() masterDataSet: Array<Product>;
  @Input() orderProductEntrySearch: boolean;
  @Input() productType: number;
  @Input() quoteFilter;
  @Output() dataSourceUpdated = new EventEmitter<Array<Product>>();

  public autocompleteFilterForm = new UntypedFormGroup({
    filter: new UntypedFormControl()
  });
  public filteredCategories: Observable<
    Array<String> | Array<Product> | Array<ProductSearchableFilter>
  >;
  public iterableDiffer: IterableDiffer<Product>;
  public PRODUCT_TYPE = ProductTypeIds;
  public searchableFilters: Array<ProductSearchableFilter>;
  public selectedFilters: Array<string> = [];

  constructor(private iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.autoComplete.closePanel();
    });
  }

  ngDoCheck(): void {
    if (this.iterableDiffer.diff(this.masterDataSet)) {
      if (this.masterDataSet.length && this.masterDataSet[0] instanceof Product) {
        if (this.productType === this.PRODUCT_TYPE.Concrete && this.orderProductEntrySearch) {
          this.searchableFilters = [
            {
              id: SEARCHABLE_FILTER_IDS.Slump,
              name: 'Slump',
              objectProperty: 'slump',
              isActive: false,
              isFormulaProp: true
            },
            {
              id: SEARCHABLE_FILTER_IDS.Strength,
              name: 'Strength',
              objectProperty: 'strength',
              isActive: false,
              isFormulaProp: true
            },
            {
              id: SEARCHABLE_FILTER_IDS.CementType,
              name: 'Cement Type',
              objectProperty: 'cementType',
              isActive: false,
              isFormulaProp: true
            },
            {
              id: SEARCHABLE_FILTER_IDS.SackEquivalent,
              name: 'Sack Equivalent',
              objectProperty: 'sackEquivalent',
              isActive: false,
              isFormulaProp: true
            },
            {
              id: SEARCHABLE_FILTER_IDS.AggregateSize,
              name: 'Aggregate Size',
              objectProperty: 'aggregateSize',
              isActive: false,
              isFormulaProp: true
            }
          ];
        } else {
          this.searchableFilters = [
            {
              id: SEARCHABLE_FILTER_IDS.Number,
              name: 'Number',
              objectProperty: 'number',
              isActive: false,
              isFormulaProp: false
            }
          ];
        }

        if (this.quoteFilter && this.masterDataSet.some((x) => Boolean(x.quoteDescription))) {
          this.searchableFilters.push({
            id: SEARCHABLE_FILTER_IDS.QuoteDescription,
            name: 'Quote Description',
            objectProperty: 'quoteDescription',
            isActive: false,
            isFormulaProp: false
          });
        } else {
          this.searchableFilters.push({
            id: SEARCHABLE_FILTER_IDS.Description,
            name: 'Description',
            objectProperty: 'description',
            isActive: false,
            isFormulaProp: false
          });
        }

        this.filteredCategories = this.autocompleteFilterForm.get('filter').valueChanges.pipe(
          startWith(null as string),
          map((category: string | null) =>
            category ? this._filter(category) : this.searchableFilters.slice()
          )
        );

        this.autocompleteFilterForm.get('filter').reset('', { emitEvent: false });
        this.selectedFilters = [];
        this.setDataSource(true);
      }
    }
  }

  public removeDuplicatesBy(keyFn: Function, array: Array<Product>): Array<Product> {
    const mySet = new Set();
    return array.filter((x) => {
      const key = keyFn(x);
      const isNew = !mySet.has(key);
      if (isNew) {
        mySet.add(key);
      }
      return isNew;
    });
  }

  public remove(filter: string): void {
    const index = this.selectedFilters.indexOf(filter);

    if (index >= 0) {
      this.selectedFilters.splice(index, 1);
    }

    this.setDataSource();
    this.searchableFilters.forEach((f) => (f.isActive = false));
  }

  public setDataSource(fromInit = false): void {
    if (!this.selectedFilters.length) {
      if (fromInit) {
        return;
      }

      this.dataSourceUpdated.emit(this.masterDataSet);
      return;
    }

    let filteredProducts = this.masterDataSet;

    this.selectedFilters.forEach((filter) => {
      const splitFilter = filter.split(':');
      const category = splitFilter[0].toLowerCase().trim();
      const value = splitFilter[1].trim();
      const filterItem = this.searchableFilters.find((o) => o.name.toLowerCase() === category);

      filteredProducts = filteredProducts.filter((p) => {
        if (
          this.productType === this.PRODUCT_TYPE.Concrete &&
          this.orderProductEntrySearch &&
          p.formula &&
          filterItem.isFormulaProp
        ) {
          // filtering on formula related properties
          const comparateValue = p.formula[filterItem.objectProperty]?.toString().trim() || '';
          return comparateValue === value.toString();
        } else if (p[filterItem.objectProperty]) {
          // filter on regular object properties
          return p[filterItem.objectProperty].trim() === value.toString();
        }

        return false;
      });
    });

    this.dataSourceUpdated.emit(filteredProducts);
  }

  public showList(id: number): boolean {
    return this.searchableFilters.find((f) => f.id === id)?.isActive;
  }

  /**
   * Event occurs when an item in the mat-autocomplete is selected.
   * During the filter process the first time the select occurs
   * the else will run setting the associated filtertype record
   * (slump, description, etc..) to active. The second
   * time the if will run setting the final filter item in an
   * array and calling setDataSource.
   */
  public selected(event: MatAutocompleteSelectedEvent): void {
    const selectedFilter = this.searchableFilters.find((f) => f.isActive);
    const category = selectedFilter ? selectedFilter.name : '';
    const viewValue = event.option.viewValue.trim();
    if (selectedFilter && selectedFilter.objectProperty) {
      this.selectedFilters.push(category + ': ' + viewValue);
      this.setDataSource();
      this.filterInput.nativeElement.value = '';
      this.autocompleteFilterForm.get('filter').setValue('');

      this.searchableFilters.forEach((f) => (f.isActive = false));
    } else {
      const reducedResults = this.searchableFilters.filter(
        (x) => x.name.toLowerCase() === viewValue.toLowerCase()
      );

      if (reducedResults.length === 1) {
        this.filterInput.nativeElement.value = reducedResults[0].name + ': ';
        this.searchableFilters.find((x) => x.id === reducedResults[0].id).isActive = true;
      } else {
        console.warn(
          `DEV: You did something wrong - result opts filter returned more than one value ${reducedResults}`
        );
      }
    }
  }

  public getItemFormula(item: any): string | number {
    if (
      (this.productType === this.PRODUCT_TYPE.Concrete &&
        this.orderProductEntrySearch &&
        !this.showList(SEARCHABLE_FILTER_IDS.Slump) &&
        !this.showList(SEARCHABLE_FILTER_IDS.Strength) &&
        !this.showList(SEARCHABLE_FILTER_IDS.CementType) &&
        !this.showList(SEARCHABLE_FILTER_IDS.SackEquivalent) &&
        !this.showList(SEARCHABLE_FILTER_IDS.AggregateSize) &&
        !this.showList(SEARCHABLE_FILTER_IDS.Description) &&
        !this.showList(SEARCHABLE_FILTER_IDS.QuoteDescription)) ||
      (((this.productType === this.PRODUCT_TYPE.Concrete && !this.orderProductEntrySearch) ||
        this.productType === this.PRODUCT_TYPE.Aggregate ||
        this.productType === this.PRODUCT_TYPE.Block ||
        this.productType === this.PRODUCT_TYPE.Other ||
        this.productType === this.PRODUCT_TYPE.RawMaterial) &&
        !this.showList(SEARCHABLE_FILTER_IDS.Description) &&
        !this.showList(SEARCHABLE_FILTER_IDS.Number) &&
        !this.showList(SEARCHABLE_FILTER_IDS.QuoteDescription))
    ) {
      return item.name;
    }

    if (this.showList(SEARCHABLE_FILTER_IDS.Description)) {
      return item.description ? item.description : item.name ? item.name : '';
    }

    if (this.showList(SEARCHABLE_FILTER_IDS.QuoteDescription)) {
      return item.quoteDescription;
    }

    if (this.productType === this.PRODUCT_TYPE.Concrete && this.orderProductEntrySearch) {
      switch (true) {
        case this.showList(SEARCHABLE_FILTER_IDS.Slump):
          return item.formula ? item.formula.slump : '';
        case this.showList(SEARCHABLE_FILTER_IDS.Strength):
          return item.formula ? item.formula.strength : '';
        case this.showList(SEARCHABLE_FILTER_IDS.CementType):
          return item.formula ? item.formula.cementType : '';
        case this.showList(SEARCHABLE_FILTER_IDS.SackEquivalent):
          return item.formula ? item.formula.sackEquivalent : '';
        case this.showList(SEARCHABLE_FILTER_IDS.AggregateSize):
          return item.formula ? item.formula.aggregateSize : '';
      }
    } else {
      if (this.showList(SEARCHABLE_FILTER_IDS.Number)) {
        return item ? item.number : '';
      }
    }
  }

  private _filter(
    value: string | ProductSearchableFilter
  ): Array<Product> | Array<ProductSearchableFilter> | Array<string> {
    // if user types characters after 'filter type' selection we will filter the available results
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();

      if (value.includes(':')) {
        const splitValue = value.split(':');
        const category = splitValue[0].toLowerCase();
        const categoryValue = splitValue[1].trim();
        const matchingFilter = this.searchableFilters.find(
          (x) => x.name.toLowerCase() === category
        );

        if (matchingFilter) {
          this.searchableFilters.forEach((f) => {
            f.isActive = f.id === matchingFilter.id;
          });

          const arr = this.masterDataSet.filter((p: Product) => {
            if (
              this.productType === ProductTypeIds.Concrete &&
              this.orderProductEntrySearch &&
              matchingFilter.isFormulaProp
            ) {
              const formulaProp = p.formula[matchingFilter.objectProperty];

              if (
                p.formula &&
                (typeof formulaProp === 'string' || typeof formulaProp === 'number')
              ) {
                return p.formula[matchingFilter.objectProperty]
                  .toString()
                  .trim()
                  .includes(categoryValue.toLowerCase());
              }
            } else {
              if (p[matchingFilter.objectProperty]) {
                return p[matchingFilter.objectProperty]
                  .trim()
                  .toLowerCase()
                  .includes(categoryValue.toLowerCase());
              }
            }
            return false;
          });

          return this.sortProductArray(
            this.removeDuplicatesBy(
              (x) =>
                this.productType === this.PRODUCT_TYPE.Concrete && this.orderProductEntrySearch
                  ? x.formula[matchingFilter.objectProperty]
                  : x[matchingFilter.objectProperty],
              arr
            ),
            matchingFilter
          );
        } else {
          return [];
        }
      }

      this.searchableFilters.forEach((f) => (f.isActive = false));
      return this.searchableFilters.filter(
        (filter) => filter.name.toLowerCase().indexOf(filterValue) === 0
      );
    } else {
      if (value && value.objectProperty) {
        setTimeout(() => {
          this.autoComplete.openPanel();
        });
        // filter for unique filter options after a filter type is selected by
        // getting unique items based on property from product or formula object
        if (
          this.productType === ProductTypeIds.Concrete &&
          this.orderProductEntrySearch &&
          value.isFormulaProp
        ) {
          return this.removeDuplicatesBy(
            (x) => x.formula[value.objectProperty],
            this.masterDataSet
          );
        } else {
          return this.removeDuplicatesBy((x) => x[value.objectProperty], this.masterDataSet);
        }
      } else {
        return [''];
      }
    }
  }

  private sortProductArray(array: Array<Product>, filter: ProductSearchableFilter): Array<Product> {
    return array.sort((a, b) => {
      let propA: number | string;
      let propB: number | string;

      if (this.productType === this.PRODUCT_TYPE.Concrete && this.orderProductEntrySearch) {
        propA = a.formula[filter.objectProperty];
        propB = b.formula[filter.objectProperty];
      } else {
        propA = a[filter.objectProperty];
        propB = b[filter.objectProperty];
      }

      if (propA > propB) {
        return 1;
      }

      if (propA < propB) {
        return -1;
      }

      return 0;
    });
  }
}

const enum SEARCHABLE_FILTER_IDS {
  Slump = 1,
  Strength = 2,
  CementType = 3,
  SackEquivalent = 4,
  AggregateSize = 5,
  Description = 6,
  Number = 7,
  QuoteDescription = 8
}
