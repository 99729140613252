export class QuickFormOption {
  id: string;
  name: string;
  visible: boolean;
  selected: boolean;
  legacy: boolean;
  object: string;

  constructor() {
    this.object = 'quickFormOption';
  }
}
