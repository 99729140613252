import { AgRendererComponent } from 'ag-grid-angular';
import { BatchLoad } from '@app/batch-load-screen/batch-load.model';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoadSchedule } from '@app/truck-tracking/load-scheduler/load-schedule.model';
import { OrderStatus } from '@app/orders/models/order-status.model';
import { OrderStatusService, SYMBOLS } from '@app/orders/shared/order-status.service';
import { Subscription } from 'rxjs';
import { UnshippedOrder } from '@app/truck-tracking/unshipped-orders/unshipped-order.model';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { ThemeService } from '@app/shared/services/theme.service';

@Component({
  selector: 'acs-ag-grid-cell-symbol',
  templateUrl: './ag-grid-cell-symbol.component.html',
  styleUrls: ['./ag-grid-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgGridCellSymbolComponent implements AgRendererComponent, OnDestroy {
  public getIdentifier = getIdentifierForIterableItem;
  public icon: IconProp;
  public orderStatuses: Array<OrderStatus>;
  public params: any;
  public tooltip: string;
  public selectedSymbolId: number;
  public selectedSymbol;
  public orderStatusSubscription: Subscription = new Subscription();

  constructor(private orderStatusService: OrderStatusService, private themeService: ThemeService) {}

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setLocalModel();
  }

  ngOnDestroy(): void {
    this.orderStatusSubscription.unsubscribe();
  }

  public setLocalModel(): void {
    if (this.params.node.data instanceof LoadSchedule) {
      this.selectedSymbol = this.params.node.data.scheduleStatusSymbol;
    } else if (this.params.node.data instanceof UnshippedOrder) {
      this.selectedSymbol = this.params.node.data.symbol;
    } else if (this.params.node.data instanceof BatchLoad) {
      this.selectedSymbol = this.params.node.data.orderStatusSymbol;
    }

    this.getOrderStatuses();
  }

  public setParamModel(): void {
    if (this.params.node.data instanceof LoadSchedule) {
      this.params.node.data.orderStatusId = this.selectedSymbolId;
    } else if (this.params.node.data instanceof BatchLoad) {
      this.params.node.data.orderStatusId = this.selectedSymbolId;
    }
  }

  public getOrderStatuses(): void {
    // todo check if status is already set
    this.orderStatusSubscription = this.orderStatusService
      .getOrderStatuses()
      .subscribe((orderStatuses: Array<OrderStatus>) => {
        this.orderStatuses = orderStatuses;
        this.params.node.data.orderStatusId = this.orderStatuses.find(
          (x) => x.symbol === this.selectedSymbol
        ).id;
        this.selectedSymbolId = this.params.node.data.orderStatusId;
        this.setIconFromSymbol(this.selectedSymbol);
      });
  }

  public getColor(params) {
    const flatColor = this.themeService.currentColorScheme === 'Dark' ? '#fff' : '#000000';
    const otherColor = this.themeService.currentColorScheme === 'Dark' ? '#ffff00' : '#0000FF';

    if (params.node.data instanceof BatchLoad) {
      return params.data.shippedLoadCount === 0 && params.data.deliveredQuantity === 0
        ? otherColor
        : flatColor;
    }

    if (params.node.data instanceof LoadSchedule) {
      return !params.data.loadsTicketed ? otherColor : flatColor;
    }

    if (params.node.data instanceof UnshippedOrder) {
      return otherColor;
    }

    return flatColor;
  }

  public setIconFromSymbol(symbol: string): void {
    const matchingSymbol = SYMBOLS[symbol];
    if (matchingSymbol) {
      this.icon = matchingSymbol.icon;
      this.tooltip = matchingSymbol.description;
    }
    this.setParamModel();
  }

  public getIconFromSymbol(symbol: string): IconDefinition {
    return SYMBOLS[symbol].icon;
  }

  public refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setLocalModel();
    return true;
  }
}
