import { AppConfig } from '@app/app.config';
import { HttpService } from '@app/security/shared/http.service';
import { ITruckType } from '@app/trucks/shared/truck-type.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable } from 'rxjs';

import { TruckType } from '@app/trucks/shared/truck-type.model';
import { TruckTypeDeliveryCharge } from '@app/administration/truck-type-maintenance/models/truck-type-delivery-charge.model';
import { TruckTypeMaintenanceForm } from '@app/administration/truck-type-maintenance/models/truck-type-maintenance-form.model';
import { map, publishReplay, refCount } from 'rxjs/operators';

const lgSfx = '\t(truck-types.service)';
const API_ENDPOINT = '/api/v1/trucktypes';

@Injectable({ providedIn: 'root' })
export class TruckTypeService {
  public truckTypes = Array<ITruckType>();

  private loadTruckTypesObserver;

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public resolve(): Observable<ITruckType[]> {
    return this.loadTruckTypes();
  }

  public getTruckTypes(): Observable<TruckType[]> {
    if (
      typeof this.loadTruckTypesObserver === 'undefined' ||
      this.loadTruckTypesObserver === null
    ) {
      this.loadTruckTypes();
    }

    return this.loadTruckTypesObserver.pipe(
      map((truckTypes: Array<ITruckType>) => {
        this.truckTypes = truckTypes.sort((a: TruckType, b: TruckType) => {
          if (a.id < b.id) {
            return 1;
          }

          if (a.id > b.id) {
            return -1;
          }

          return 0;
        });

        return this.truckTypes;
      })
    );
  }

  public loadTruckTypes(): Observable<Array<ITruckType>> {
    this.log.log(`Loading Truck Types from the server ${lgSfx}`);
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    if (this.loadTruckTypesObserver) {
      return this.loadTruckTypesObserver;
    }

    this.loadTruckTypesObserver = this.http.getList(endpoint, TruckType).pipe(
      map((truckTypesResp) => {
        this.truckTypes.splice(0, this.truckTypes.length);
        this.truckTypes = truckTypesResp;
        return this.truckTypes;
      }),
      publishReplay(1),
      refCount()
    );
    return this.loadTruckTypesObserver;
  }

  public saveTruckType(data: TruckTypeMaintenanceForm): Observable<TruckTypeMaintenanceForm> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http.post<TruckTypeMaintenanceForm>(
      endpoint,
      data,
      TruckTypeMaintenanceForm,
      undefined,
      true
    );
  }

  public updateTruckType(
    truckTypeFormId: number,
    data: TruckTypeMaintenanceForm
  ): Observable<TruckTypeMaintenanceForm> {
    const endpoint = this.config.getServerUri() + API_ENDPOINT + `/${truckTypeFormId}`;

    return this.http.put<TruckTypeMaintenanceForm>(
      endpoint,
      data,
      TruckTypeMaintenanceForm,
      undefined,
      true
    );
  }

  public getTruckTypeDeliveryCharges(
    truckTypeId: number
  ): Observable<Array<TruckTypeDeliveryCharge>> {
    const endpoint =
      this.config.getServerUri() + API_ENDPOINT + '/delivery-charges' + `/${truckTypeId}`;

    return this.http.getList<TruckTypeDeliveryCharge>(endpoint, TruckTypeDeliveryCharge);
  }

  public getTruckTypeDefaultLoadSize(systemTypeId: number): Observable<number> {
    return this.getTruckTypes().pipe(
      map((truckTypes: Array<TruckType>) => {
        const types = truckTypes
          .filter((x) => x.systemTypeId === systemTypeId)
          .sort((a, b) => b.fullLoadQuantity - a.fullLoadQuantity);
        return types?.[0]?.fullLoadQuantity;
      })
    );
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.truckTypes = Array<ITruckType>();
    this.loadTruckTypesObserver = undefined;
  }
}
