import { Moment } from 'moment';
import { convertIso8601ToDate } from '@app/shared/utilities/date-helpers.utility';

export class OipLoad {
  mobileSimulation: boolean;
  useBatchPanel: boolean;
  truckFullLoadAllowedMinutes: number;
  truckId: number;
  object: string;
  minutes: number;
  primaryProductQuantity: number;
  primaryProductDescription: string;
  homePlantId: number;
  id: number;
  ticketId: number;
  plantColorId: number;
  ticketedPlantId: number;
  truckFlagId: number;
  truckFlag: string;
  truckNumber: string;
  ticketStatus: number;
  orderId: number;
  customerName: string;

  private _firstLoadOnJob: Moment;
  get firstLoadOnJob(): Moment {
    return this._firstLoadOnJob;
  }
  set firstLoadOnJob(val) {
    this._firstLoadOnJob = convertIso8601ToDate(val);
  }

  private _ticketOnJob: Moment;
  get ticketOnJob(): Moment {
    return this._ticketOnJob;
  }
  set ticketOnJob(val) {
    this._ticketOnJob = convertIso8601ToDate(val);
  }

  private _onJob: Moment;
  get onJob(): Moment {
    return this._onJob;
  }
  set onJob(val) {
    this._onJob = convertIso8601ToDate(val);
  }

  private _statusTime: Moment;
  get statusTime(): Moment {
    return this._statusTime;
  }
  set statusTime(val) {
    this._statusTime = convertIso8601ToDate(val);
  }

  constructor() {}
}
