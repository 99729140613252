import { AppConfig } from '@app/app.config';
import { AppSetting } from '@app/settings/shared/setting.model';
import { BooleanResponse } from '@app/shared/models/boolean-response.model';
import { HttpOptionsService, HttpService } from '@app/security/shared/http.service';
import { IAppSetting } from '@app/settings/shared/setting.interface';
import { Injectable } from '@angular/core';
import { Logger } from '@app/logger/logger';
import { LoginStatusModel } from '@app/security/shared/login-status-change.model';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

const lgSfx = '\t(settings.service)';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  public settings = Array<IAppSetting<any>>();

  constructor(
    private config: AppConfig,
    private http: HttpService,
    private log: Logger,
    private loginStatusService: LoginStatusService,
    private optionsService: HttpOptionsService
  ) {
    this.loginStatusService.loginStatus.subscribe((ls) => {
      this.onLoginStatusChange(ls);
    });
  }

  public getSettingById(id: string): Observable<IAppSetting<any>> {
    if (typeof this.settings[id] === 'undefined' || this.settings[id] === null) {
      this.log.log(`AppSetting( ${id}) loading ${lgSfx}`);
      const API_ENDPOINT = '/api/settings';
      const endpoint = this.config.getServerUri() + API_ENDPOINT + `?id=${id}`;

      return this.http.getAny(endpoint, undefined, true).pipe(
        map((data: any) => {
          this.log.log(`AppSetting(${id}) loaded ${lgSfx}`);
          const appSetting = new AppSetting(data.id, JSON.parse(data.data));
          this.settings[appSetting.id] = appSetting;
          return appSetting;
        }),
        catchError((err) => {
          if (err.responseError && err.responseError.status === 404) {
            return of(undefined);
          } else {
            this.log.error(`Error loading setting(${id}) from API server ${lgSfx}`, err);
            return throwError('Error loading setting from API.');
          }
        })
      );
    } else {
      return of(this.settings[id]).pipe(map((setting) => setting));
    }
  }

  public getSettingByIdOrDefault(id: string, defaultValue: any): Observable<IAppSetting<any>> {
    return this.getSettingById(id).pipe(
      switchMap((resGetSetting: IAppSetting<any>) => {
        if (resGetSetting !== undefined) {
          return of(resGetSetting);
        } else {
          const newSetting = new AppSetting(id, defaultValue);

          return this.saveSetting(newSetting).pipe(switchMap(() => of(newSetting)));
        }
      })
    );
  }

  public saveSetting(setting: IAppSetting<any>): Observable<boolean> {
    this.log.log(`AppSetting(${setting.id}) saving ${lgSfx}`);
    if (setting.isValid() === false) {
      this.log.error(`Error saving setting(${setting.id}) to the API server ${lgSfx}`);
      return throwError(`invalid setting properties`);
    }

    const API_ENDPOINT = '/api/settings';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    const body = { id: setting.id, data: JSON.stringify(setting.data) };

    return this.http.postAny(endpoint, body).pipe(
      map((resp) => {
        this.log.log(`AppSetting(${setting.id}) saved ${lgSfx}`);
        this.settings[setting.id] = setting;

        return resp;
      })
    );
  }

  public updateSetting(setting: IAppSetting<any>): Observable<boolean> {
    this.log.log(`AppSetting(${setting.id}) updating ${lgSfx}`);
    if (setting.isValid() === false) {
      this.log.error(`Error updating setting(${setting.id}) on the API server ${lgSfx}`);
      return throwError(`invalid setting properties`);
    }

    const API_ENDPOINT = '/api/settings';
    const endpoint = this.config.getServerUri() + API_ENDPOINT;
    const body = { id: setting.id, data: JSON.stringify(setting.data) };
    return this.http.putAny(endpoint, body).pipe(
      map((response) => {
        this.log.log(`AppSetting(${setting.id}) updated ${lgSfx}`);
        this.settings[setting.id] = setting;

        return response;
      })
    );
  }

  public deleteDashboardSetting(id): Observable<boolean> {
    const API_ENDPOINT = `/api/settings/dashboard/widget/${id}`;
    const endpoint = this.config.getServerUri() + API_ENDPOINT;

    return this.http
      .delete<BooleanResponse>(endpoint, BooleanResponse, this.optionsService.get(), true)
      .pipe(
        map((data: BooleanResponse) => {
          if (data.value) {
            this.log.log(`AppSetting(${id}) deleted`);
          } else {
            this.log.warn(`AppSetting(${id}) was not deleted. Check server logs for details.`);
          }

          return data.value;
        }),
        catchError((err) => {
          this.log.error(`Error deleting setting(${id}) from server`, err);
          return throwError('Error deleting setting from server.');
        })
      );
  }

  private onLoginStatusChange(loginStatus: LoginStatusModel): void {
    if (loginStatus.loggedIn === false) {
      this.cleanCache();
    }
  }

  private cleanCache(): void {
    this.settings = Array<IAppSetting<any>>();
  }
}
