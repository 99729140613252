import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SearchToggle, SearchToggleEnum } from '@app/ui/search-toggle/search-toggle.model';

@Component({
  selector: 'acs-search-toggle',
  styleUrls: ['./search-toggle.component.scss'],
  templateUrl: './search-toggle.component.html'
})
export class SearchToggleComponent implements OnInit {
  @Input() searchOption: SearchToggle;
  @Output() updated = new EventEmitter();

  public enum = SearchToggleEnum;

  ngOnInit(): void {
    if (this.searchOption === null) {
      this.searchOption = new SearchToggle(SearchToggleEnum.inactive);
    }
  }
  public onValChange(): void {
    this.updated.emit();
  }
}
