import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppSetting } from '@app/settings/shared/setting.model';
import { AUJS_FORMS } from '@app/forms/shared/aujs-forms';
import { AuthenticationService } from '@app/security/shared/authentication.service';
import { DispatchService } from '@app/dispatcher/dispatch.service';
import { IAppSetting } from '@app/settings/shared/setting.interface';
import { LegacyService } from '@app/legacy/legacy.service';
import { LoginStatusService } from '@app/security/shared/login-status.service';
import { MatDialog } from '@angular/material/dialog';
import {
  MEDIUM_SCREEN_PX,
  SMALL_SCREEN_PX,
  WindowService
} from '@app/shared/services/browser/window.service';
import { QuickFormOption } from '@app/ui/toolbar/models/quick-form-option.model';
import { ReportSettingsManager } from '@app/reports/report-settings/report-settings-manager.model';
import { ReportSettingsService } from '@app/reports/report-settings/report-settings.service';
import { SecurityService } from '@app/security/shared/security.service';
import { Setting } from '@app/reports/report-settings/setting.model';
import { SettingDialogComponent } from '@app/ui/shared/settings-dialog.component';
import { SettingsService } from '@app/settings/shared/settings.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '@app/shared/services/theme.service';
import { faCog, faPalette, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { getIdentifierForIterableItem } from '@app/shared/utilities/trackby.utility';
import { hasValue } from '@app/shared/utilities/comparison-helpers.utility';

const TOOLBAR_SETTING_ID = 'tlbr:QuickLaunchForms';

@Component({
  selector: 'acs-toolbar',
  templateUrl: 'toolbar.component.html',
  styleUrls: ['toolbar.component.scss']
})
export class ToolbarComponent implements AfterViewInit, OnDestroy {
  public getIdentifierForIterableItem = getIdentifierForIterableItem;
  public loginObserver: Subscription;
  public paletteIcon = faPalette;
  public cogIcon = faCog;
  public powerOffIcon = faPowerOff;
  public selectedFormIds: string[];
  public mediumScreenWidth = MEDIUM_SCREEN_PX;
  public smallScreenWidth = SMALL_SCREEN_PX + 50;
  public userName: string;
  public userLoggedIn: boolean;
  public userSettings: ToolbarUserSettingsModel;
  public windowWidth: number;

  private quickLaunchSetting: IAppSetting<any>;

  constructor(
    public matDialog: MatDialog,
    private appSettingsService: SettingsService,
    private authService: AuthenticationService,
    private dispatchService: DispatchService,
    private legacyService: LegacyService,
    private loginStatusService: LoginStatusService,
    private reportSettingsService: ReportSettingsService,
    private securityService: SecurityService,
    private themeService: ThemeService,
    private windowService: WindowService
  ) {
    this.selectedFormIds = [];
    this.userLoggedIn = this.securityService.getUserLoggedIn();

    this.userSettings = new ToolbarUserSettingsModel();
    this.userSettings.addSetting(this.reportSettingsService.getQuickFormsSetting());

    this.loginObserver = this.loginStatusService.loginStatus.subscribe((loginStatus) => {
      this.userLoggedIn = loginStatus.loggedIn;
      if (this.userLoggedIn) {
        this.userName = loginStatus.username;

        if (!this.authService.getOnlyAdminStatus()) {
          this.appSettingsService
            .getSettingById(TOOLBAR_SETTING_ID)
            .subscribe((setting: IAppSetting<any>) => {
              this.onQuickLaunchSettingRetrieved(setting);
            });
        }
      } else {
        this.userName = undefined;
        this.userSettings.clean();
        this.userSettings.addSetting(this.reportSettingsService.getQuickFormsSetting());
      }
    });
  }

  ngDoCheck(): void {
    this.windowWidth = this.windowService.screenSize.screenWidth;
  }

  public ngAfterViewInit(): void {
    this.dispatchService.quickLaunchEvents.subscribe((quickLaunchUpdate) => {
      if (quickLaunchUpdate.data.username === this.securityService.getUsername()) {
        this.userSettings.forms.loadAppSettingValue(quickLaunchUpdate.data.formIds);
        this.classicSettingsFilterCheck();
        this.setSelectedFormIds();
        this.quickLaunchSetting.data = this.userSettings.forms.getAppSettingValue();
      }
    });
  }

  public ngOnDestroy(): void {
    this.loginObserver.unsubscribe();
  }

  public showSettings(): void {
    this.matDialog
      .open(SettingDialogComponent, {
        data: {
          name: 'User Settings',
          settings: this.userSettings
        },
        height: '70vh',
        maxHeight: '95vh',
        maxWidth: '1200px',
        width: '85vw',
        panelClass: 'acs-dialog-non-padded',
        autoFocus: true
      })
      .afterClosed()
      .subscribe((result) => {
        result
          ? this.updateDisplayedForms()
          : this.userSettings.forms.loadAppSettingValue(this.selectedFormIds);
      });
  }

  public logout(): void {
    this.securityService.logout();
  }

  public toggleTheme(): void {
    this.themeService.toggleThemeColor();
  }

  /************************
   * Private
   ***********************/

  private onQuickLaunchSettingRetrieved(quickLaunchSettingFromServer: IAppSetting<any>): void {
    this.userSettings.forms.whenReady().subscribe(() => {
      if (hasValue(quickLaunchSettingFromServer)) {
        this.quickLaunchSetting = quickLaunchSettingFromServer;
        this.userSettings.forms.loadAppSettingValue(this.quickLaunchSetting.data);
        this.updateDisplayedForms();
      } else {
        this.userSettings.forms.loadAppSettingValue([AUJS_FORMS.FormNgDashboard.enumId]);
        this.quickLaunchSetting = new AppSetting(
          TOOLBAR_SETTING_ID,
          this.userSettings.forms.getAppSettingValue()
        );
        this.appSettingsService.saveSetting(this.quickLaunchSetting).subscribe(() => {
          this.updateDisplayedForms();
        });
      }
    });
  }

  private updateDisplayedForms(): void {
    this.setSelectedFormIds();

    this.quickLaunchSetting.data = this.userSettings.forms.getAppSettingValue();
    this.appSettingsService.updateSetting(this.quickLaunchSetting).subscribe();
  }

  private classicSettingsFilterCheck(): void {
    if (this.userSettings.forms?.type === 'multiselect' && this.legacyService.hideLegacyForms) {
      const dataToFilter = this.userSettings.forms.options;

      if (dataToFilter?.length > 0) {
        this.userSettings.forms.options = dataToFilter.filter(
          (option: QuickFormOption) => !option.legacy
        );
      }
    }
  }

  private setSelectedFormIds(): void {
    this.selectedFormIds = [];

    this.userSettings.forms.options.forEach((option: QuickFormOption) => {
      if (option.selected) {
        this.selectedFormIds.push(option.id);
      }
    });
  }
}

class ToolbarUserSettingsModel extends ReportSettingsManager {
  declare forms: Setting;

  constructor() {
    super();
  }
}
