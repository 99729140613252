import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartialPlant } from '@app/plants/shared/partial-plant.model';
import { OptionMultiSelectDialogComponent } from '@app/ui/option-multi-select/option-multi-select-dialog.component';
import { Setting } from '@app/reports/report-settings/setting.model';
import { jsonCopy } from '@app/shared/utilities/json-copy.utility';

@Component({
  selector: 'acs-option-filter-select',
  templateUrl: './option-filter-select.component.html',
  styleUrls: ['./option-filter-select.component.scss']
})
export class OptionFilterSelectComponent implements OnChanges {
  @Input() setting: Setting;
  @Output() visibilityChanged = new EventEmitter<Setting>();
  @Input() alignButtonsTop = false;

  public filteredOptions: Array<PartialPlant>;
  constructor(private matDialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setting && changes.setting.currentValue !== changes.setting.previousValue) {
      this.filteredOptions = changes.setting.currentValue.options.filter(
        (plant: PartialPlant) => plant.selected
      );
    }
  }

  public showDisplayDialog(): void {
    const localSettings = jsonCopy(this.setting);
    this.matDialog
      .open(OptionMultiSelectDialogComponent, {
        data: {
          title: 'Visibility Configuration',
          setting: localSettings,
          showDragDrop: true,
          minLimitSave: true
        },
        maxWidth: '80vw',
        panelClass: ['acs-dialog-non-padded']
      })
      .afterClosed()
      .subscribe((result) => {
        if (result === undefined) {
          console.log(result);
        } else if (result) {
          this.filteredOptions = result;
          this.filteredOptions.map((plant: PartialPlant) => {
            plant.visible = true;
          });
          this.visibilityUpdated();
        }
      });
  }

  public visibilityUpdated(): void {
    this.setSelectedChoices(this.filteredOptions);
    this.visibilityChanged.emit(this.setting);
  }
  public selectAll(): void {
    this.filteredOptions.map((plant: PartialPlant) => {
      plant.visible = true;
    });
    this.visibilityUpdated();
  }

  public unselectAll(): void {
    this.filteredOptions.map((plant: PartialPlant) => {
      plant.visible = false;
    });
    this.visibilityUpdated();
  }

  private setSelectedChoices(choices: PartialPlant[]) {
    if (this.setting && this.setting.options) {
      this.setting.options.forEach((option) => {
        const matchingChoice = choices.find((choice) => choice.id === option.id);
        if (matchingChoice) {
          option.selected = matchingChoice.selected;
          option.visible = matchingChoice.visible;
        } else {
          option.selected = false;
          option.visible = false;
        }
      });
    }
  }
}
