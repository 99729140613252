import moment from 'moment';
import { ITruckType } from '@app/trucks/shared/truck-type.interface';
import {
  convertDateToIsoString,
  convertIso8601ToDate
} from '@app/shared/utilities/date-helpers.utility';

export class TruckType implements ITruckType {
  id: number;
  maxGrossWeight: number;
  description: string;
  fullLoadQuantity: number;
  isRail: boolean;
  minimumLoadQuantity: number;
  object: string;
  systemTypeId: number;
  selected: boolean;

  get isMaxGrossWeightValid(): boolean {
    return this.maxGrossWeight && this.maxGrossWeight > 0;
  }

  private _fullLoadTimeAllowed: moment.Moment;
  get fullLoadTimeAllowed(): moment.Moment {
    return this._fullLoadTimeAllowed;
  }
  set fullLoadTimeAllowed(val) {
    this._fullLoadTimeAllowed = convertIso8601ToDate(val);
  }

  constructor(
    id?: number,
    maxGrossWeight?: number,
    description?: string,
    fullLoadQuantity?: number,
    fullLoadTimeAllowed?: moment.Moment,
    isRail?: boolean,
    minimumLoadQuantity?: number,
    systemTypeId?: number,
    selected?: boolean
  ) {
    this.id = id;
    this.maxGrossWeight = maxGrossWeight;
    this.description = description;
    this.fullLoadQuantity = fullLoadQuantity;
    this.fullLoadTimeAllowed = fullLoadTimeAllowed || moment().startOf('day');
    this.isRail = isRail;
    this.minimumLoadQuantity = minimumLoadQuantity;
    this.systemTypeId = systemTypeId;
    this.object = 'truckType';
    this.selected = selected || false;
  }

  toJSON(): Object {
    return convertDateToIsoString(this);
  }
}
